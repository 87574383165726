import React, { useState, useMemo } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import EditIcon from '@mui/icons-material/Edit';

function ChartCard({ chart, onEdit }) {
  const { name, seatsio_chart_thumbnail_url, categories } = chart;
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const location = useMemo(
    () =>
      chart.address && chart.latitude && chart.longitude
        ? `${chart.address} ${onEdit ? `${chart.latitude}, ${chart.longitude}` : ''}`
        : '',
    [chart, onEdit]
  );

  const status = useMemo(() => (chart.status === 'ARCHIVED' && onEdit ? '(Archived)' : ''), [chart, onEdit]);

  return (
    <Card sx={{ width: '300px' }}>
      <CardHeader
        sx={{
          '& .MuiCardHeader-content': {
            overflow: 'hidden',
          },
        }}
        titleTypographyProps={{ noWrap: true }}
        title={`${name} ${status}`}
        subheader={location}
        action={
          onEdit && (
            <IconButton onClick={() => onEdit(chart)}>
              <EditIcon />
            </IconButton>
          )
        }
      />
      <Divider />
      <CardMedia sx={{ height: 300 }} component="img" image={seatsio_chart_thumbnail_url} alt="Chart thumbnail" />
      <CardActions disableSpacing>
        <Button size="small" onClick={handleExpandClick} disabled={!categories.length}>
          Categories
        </Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto">
        <CardContent>
          <List dense>
            {categories.map(({ id, name, color }) => (
              <ListItem sx={{ mb: 1, borderLeft: `5px solid ${color}` }} key={id} divider>
                <ListItemText primary={`${name}`} />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default ChartCard;
