import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Link as RouterLink, useNavigate } from 'react-router-dom';
import { fetchToken } from '../redux/slices/authSlice';
import validator from 'validator';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import { Link } from '@mui/material';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const error = useSelector((state) => state.auth.error);
  const user = useSelector((state) => state.auth.user);

  const redirect = useMemo(
    () => user && (user.role === 'organizer' || user.role === 'cashier' ? '/events' : '/orders'),
    [user]
  );

  useEffect(() => {
    if (error) {
      toast.error('Wrong username or password', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [error]);

  const formConfig = {
    email: {
      value: '',
      error: false,
      errorText: 'Field is not a valid email',
      isValid: (value) => {
        return validator.isEmail(value);
      },
    },
    password: {
      value: '',
      error: false,
      errorText: 'Field is not a valid password',
      isValid: (value) => {
        return !validator.isEmpty(value, { ignore_whitespace: true });
      },
    },
  };

  const [formState, setFormState] = useState(formConfig);

  const onInputChange = (input) => {
    setFormState((currentState) => ({
      ...currentState,
      [input.target.name]: {
        ...currentState[input.target.name],
        value: input.target.type === 'checkbox' ? input.target.checked : input.target.value,
        error: false,
      },
    }));
  };

  const onFormSubmit = () => {
    const inputs = Object.keys(formState);
    const newState = Object.assign({}, formState);
    let hasError = false;
    for (const input of inputs) {
      if (!newState[input].isValid(newState[input].value)) {
        hasError = true;
        newState[input].error = true;
      }
    }

    setFormState(newState);

    if (!hasError) {
      const payload = {
        email: newState.email.value,
        password: newState.password.value,
      };

      dispatch(fetchToken(payload)).then(({ payload }) => {
        if (payload) {
          const { role } = payload;
          const redirect = role === 'organizer' || role === 'cashier' ? '/events' : '/orders';
          navigate(redirect);
        }
      });
    }
  };

  return (
    <>
      {user && <Navigate to={redirect} />}
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <Typography sx={{ color: 'white', textDecoration: 'none' }} variant="h6" noWrap>
            POFTIM CULTURA
          </Typography>
        </Toolbar>
      </AppBar>
      <Container sx={{ height: '100vh' }}>
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Paper sx={{ p: 2, width: 350 }}>
            <Typography variant="h5">Welcome</Typography>
            <form>
              <TextField
                value={formState.email.value}
                onChange={onInputChange}
                error={formState.email.error}
                helperText={formState.email.error ? formState.email.errorText : ''}
                type="text"
                variant="standard"
                margin="normal"
                size="small"
                fullWidth
                name="email"
                label="Email"
              />
              <TextField
                value={formState.password.value}
                onChange={onInputChange}
                error={formState.password.error}
                helperText={formState.password.error ? formState.password.errorText : ''}
                type="password"
                variant="standard"
                margin="normal"
                size="small"
                fullWidth
                name="password"
                label="Password"
              />
            </form>
            <Button sx={{ mt: 2, mb: 2 }} fullWidth disabled={isLoading} onClick={onFormSubmit} variant="contained">
              Continue
            </Button>
            <Link component={RouterLink} to="/reset-password">
              Forgot password?
            </Link>
          </Paper>
        </Box>
      </Container>
    </>
  );
}

export default Login;
