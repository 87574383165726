import React, { useEffect, useMemo, useState } from 'react';
import { createOrganizer, updateOrganizer } from '../redux/slices/organizersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import validator from 'validator';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
//import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const empty = (value) => validator.isEmpty(value, { ignore_whitespace: true });

function OrganizerDrawer({ open, onClose, organizer }) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.organizers.isLoading);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [name, setName] = useState('');
  const [comission, setComission] = useState('');
  const [notificationSmsPrice, setNotificationSmPrice] = useState('');
  const [notificationEmailPrice, setNotificationEmailPrice] = useState('');
  const [comissionError, setComissionError] = useState(false);
  const [smsPriceError, setSmsPriceError] = useState(false);
  const [emailPriceError, setEmailPriceError] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [taxCode, setTaxCode] = useState('');
  const [regCode, setRegCode] = useState('');
  const [isVatPayer, setIsVatPayer] = useState(false);
  const [reprName, setReprName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [county, setCounty] = useState('');
  const [fiscalPrefix, setFiscalPrefix] = useState('');
  const [contractId, setContractId] = useState('');
  const [seatPrice, setSeatPrice] = useState('');
  const [seatPriceError, setSeatPriceError] = useState(false);
  const [comissionPerSeat, setComissionPerSeat] = useState(false);

  const isActionDisabled = useMemo(
    () =>
      empty(name) ||
      empty(comission) ||
      empty(notificationSmsPrice) ||
      empty(notificationEmailPrice) ||
      empty(companyName) ||
      empty(taxCode) ||
      empty(reprName) ||
      empty(address) ||
      empty(city) ||
      empty(county) ||
      empty(fiscalPrefix) ||
      empty(contractId) ||
      comissionError ||
      smsPriceError ||
      emailPriceError ||
      seatPriceError,
    [
      name,
      comission,
      notificationSmsPrice,
      notificationEmailPrice,
      companyName,
      taxCode,
      reprName,
      address,
      city,
      county,
      fiscalPrefix,
      contractId,
      comissionError,
      smsPriceError,
      emailPriceError,
      seatPriceError,
    ]
  );

  const onDrawerClose = () => {
    onClose();
    setName('');
    setSelectedStatus('');
    setComission('');
    setNotificationSmPrice('');
    setNotificationEmailPrice('');
    setCompanyName('');
    setTaxCode('');
    setTaxCode('');
    setReprName('');
    setAddress('');
    setCity('');
    setCounty('');
    setFiscalPrefix('');
    setContractId('');
    setComissionError(false);
    setSmsPriceError(false);
    setEmailPriceError(false);
    setIsVatPayer(false);
    setSeatPrice('');
    setSeatPriceError(false);
    setComissionPerSeat(false);
  };

  const onUpdateComission = (event) => {
    setComissionError(false);
    const number = event.target.value;
    setComission(number);
    if (number === '' || !(Number.isInteger(+number) && number >= 0 && number <= 100)) {
      setComissionError(true);
    }
  };

  const onUpdateSmsPrice = (event) => {
    setSmsPriceError(false);
    const number = event.target.value;
    setNotificationSmPrice(number);
    if (!/^\s*-?[0-9]\d*(\.\d{1,2})?\s*$/.test(number)) {
      setSmsPriceError(true);
    }
  };

  const onUpdateEmailPrice = (event) => {
    setEmailPriceError(false);
    const number = event.target.value;
    setNotificationEmailPrice(number);
    if (!/^\s*-?[0-9]\d*(\.\d{1,2})?\s*$/.test(number)) {
      setEmailPriceError(true);
    }
  };

  const onUpdateSeatPrice = (event) => {
    setSeatPriceError(false);
    const number = event.target.value;
    setSeatPrice(number);

    //if (!number.trim()) return;

    if (!/^\s*-?[0-9]\d*(\.\d{1,2})?\s*$/.test(number)) {
      setSeatPriceError(true);
    }
  };

  useEffect(() => {
    if (organizer) {
      const {
        name,
        status,
        platform_comission,
        seat_price,
        notification_sms_price,
        notification_email_price,
        companies,
      } = organizer;

      const {
        company_name,
        tax_code,
        reg_code,
        is_vat_payer,
        address,
        city,
        county,
        representative_full_name,
        fiscal_serial_prefix,
        contract_id,
      } = companies.find((company) => company.is_default);

      setName(name);
      setSelectedStatus(status);
      setComission((platform_comission * 100).toString());
      setNotificationSmPrice(notification_sms_price);
      setNotificationEmailPrice(notification_email_price);
      setCompanyName(company_name);
      setTaxCode(tax_code);
      setRegCode(reg_code);
      setIsVatPayer(!!is_vat_payer);
      setAddress(address);
      setCity(city);
      setCounty(county);
      setReprName(representative_full_name);
      setFiscalPrefix(fiscal_serial_prefix);
      setContractId(contract_id);
      setSeatPrice(seat_price || '');
    }
  }, [organizer]);

  const onCreateOrganizer = () => {
    const payload = {
      name: name.trim(),
      organizer_pricing: {
        comission,
        seat_price: seatPrice,
        comission_cash_per_seat: comissionPerSeat,
        notifications_sms_price: notificationSmsPrice,
        notifications_email_price: notificationEmailPrice,
      },
      company: {
        name: companyName,
        tax_code: taxCode,
        reg_code: regCode,
        is_vat_payer: isVatPayer,
        address,
        city,
        county,
        representative_full_name: reprName,
        fiscal_serial_prefix: fiscalPrefix,
        contract_id: contractId,
      },
    };
    dispatch(createOrganizer(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('Organizer created successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        onDrawerClose();
      }
    });
  };

  const onUpdateOrganizer = () => {
    const payload = {
      organizerId: organizer.id,
      status: selectedStatus,
      name: name.trim(),
      organizer_pricing: {
        comission,
        seat_price: seatPrice,
        notifications_sms_price: notificationSmsPrice,
        notifications_email_price: notificationEmailPrice,
      },
      company: {
        name: companyName,
        tax_code: taxCode,
        reg_code: regCode,
        is_vat_payer: isVatPayer,
        address,
        city,
        county,
        representative_full_name: reprName,
        fiscal_serial_prefix: fiscalPrefix,
        contract_id: contractId,
      },
    };
    dispatch(updateOrganizer(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('Organizer updated successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        onDrawerClose();
      }
    });
  };

  return (
    <Drawer anchor="right" open={open}>
      <Toolbar />
      <Toolbar>
        <Typography variant="button">{!organizer ? 'Create organizer' : 'Edit organizer'}</Typography>
      </Toolbar>
      <Divider />
      <Box sx={{ width: 400, pl: 2, pr: 2 }}>
        <Box sx={{ pb: 3 }} component="form">
          <TextField
            required
            value={name}
            onChange={(event) => setName(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            label="Name"
            type="text"
          />
          {organizer && (
            <TextField
              select
              value={selectedStatus}
              onChange={(event) => setSelectedStatus(event.target.value)}
              fullWidth
              variant="standard"
              margin="normal"
              size="small"
              label="Status"
            >
              <MenuItem value={'ACTIVE'}>ACTIVE</MenuItem>
              <MenuItem value={'INACTIVE'}>INACTIVE</MenuItem>
            </TextField>
          )}
        </Box>
        <Box sx={{ pb: 3 }} component="form">
          <Typography gutterBottom variant="button">
            Pricing
          </Typography>
          <TextField
            required
            value={comission}
            onChange={onUpdateComission}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            label="Comission (%)"
            type="text"
          />
          <TextField
            value={seatPrice}
            onChange={onUpdateSeatPrice}
            fullWidth
            required
            variant="standard"
            margin="normal"
            size="small"
            label="Seat price (Lei)"
            type="text"
          />
          {!organizer && (
            <FormGroup sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Switch checked={comissionPerSeat} onChange={(event) => setComissionPerSeat(event.target.checked)} />
                }
                label="Comission cash per seat"
              />
            </FormGroup>
          )}
          <TextField
            required
            value={notificationSmsPrice}
            onChange={onUpdateSmsPrice}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            label="Sms price (Lei)"
            type="text"
          />
          <TextField
            required
            value={notificationEmailPrice}
            onChange={onUpdateEmailPrice}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            label="Email price (Lei)"
            type="text"
          />
        </Box>
        <Box component="form">
          <Typography gutterBottom variant="button">
            Company
          </Typography>
          <TextField
            required
            value={companyName}
            onChange={(event) => setCompanyName(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            label="Company name"
            type="text"
          />
          <TextField
            required
            value={reprName}
            onChange={(event) => setReprName(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            label="Representative full name"
            type="text"
          />
          <TextField
            required
            value={taxCode}
            onChange={(event) => setTaxCode(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            label="Tax code"
            type="text"
          />
          <TextField
            value={regCode}
            onChange={(event) => setRegCode(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            label="Reg. code"
            type="text"
          />
          <FormGroup sx={{ mt: 2 }}>
            <FormControlLabel
              control={<Switch checked={isVatPayer} onChange={(event) => setIsVatPayer(event.target.checked)} />}
              label="VAT Payer"
            />
          </FormGroup>
          <TextField
            required
            value={address}
            onChange={(event) => setAddress(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            label="Address"
            type="text"
          />
          <TextField
            required
            value={city}
            onChange={(event) => setCity(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            label="City"
            type="text"
          />
          <TextField
            required
            value={county}
            onChange={(event) => setCounty(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            label="County"
            type="text"
          />
          <TextField
            required
            value={fiscalPrefix}
            onChange={(event) => setFiscalPrefix(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            label="Fiscal serial prefix"
            type="text"
          />
          <TextField
            required
            value={contractId}
            onChange={(event) => setContractId(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            label="Contract ID"
            type="text"
          />
        </Box>
      </Box>
      <Box sx={{ p: 2, mt: 'auto' }}>
        <Grid columnSpacing={2} container>
          <Grid xs={6} item>
            <Button fullWidth variant="outlined" onClick={onDrawerClose}>
              Cancel
            </Button>
          </Grid>
          <Grid xs={6} item>
            <Button
              disabled={isLoading || isActionDisabled}
              fullWidth
              variant="contained"
              onClick={!organizer ? onCreateOrganizer : onUpdateOrganizer}
            >
              {!organizer ? 'Create' : 'Update'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}

export default OrganizerDrawer;
