import dayjs from 'dayjs';
import validator from 'validator';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, useMediaQuery } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { createOrder } from '../redux/slices/ordersSlice';
import seatInfoFormatter from '../utils/seatInfoFormatter';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

function CheckoutDialog({ open, onClose, event, seats, setPaymentFormData, setOrderError }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const user = useSelector((state) => state.auth.user);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLoading = useSelector((state) => state.orders.isLoading);
  const holdToken = useSelector(({ cart: { holdToken } }) => holdToken);
  const objectIds = useMemo(() => seats.map(({ id, price }) => ({ id, expectedPrice: price })), [seats]);
  const totalPrice = useSelector(({ cart: { totalPrice } }) => totalPrice);

  const formConfig = {
    firstName: {
      value: '',
      error: false,
      errorText: 'Câmp necompletat',
      isValid: (value) => {
        return !validator.isEmpty(value, { ignore_whitespace: true });
      },
    },
    lastName: {
      value: '',
      error: false,
      errorText: 'Câmp necompletat',
      isValid: (value) => {
        return !validator.isEmpty(value, { ignore_whitespace: true });
      },
    },
    email: {
      value: '',
      error: false,
      errorText: 'Email invalid',
      isValid: (value) => {
        return validator.isEmail(value);
      },
    },
    phone: {
      value: '',
      error: false,
      errorText: 'Număr telefon invalid',
      isValid: (value) => {
        return validator.isMobilePhone(value);
      },
    },

    agreeToTerms: {
      value: false,
      error: false,
      isValid: (value) => {
        return value;
      },
    },
  };

  const [formState, setFormState] = useState(formConfig);

  const onInputChange = (input) => {
    setFormState((currentState) => ({
      ...currentState,
      [input.target.name]: {
        ...currentState[input.target.name],
        value: input.target.type === 'checkbox' ? input.target.checked : input.target.value,
        error: false,
      },
    }));
  };

  const onFormSubmit = () => {
    const inputs = Object.keys(formState);
    const newState = Object.assign({}, formState);
    let hasError = false;
    for (const input of inputs) {
      if (!newState[input].isValid(newState[input].value)) {
        hasError = true;
        newState[input].error = true;
      }
    }

    setFormState(newState);

    if (!hasError) {
      const payload = {
        event_id: event.id,
        user: {
          email: newState.email.value,
          first_name: newState.firstName.value,
          last_name: newState.lastName.value,
          phone: newState.phone.value,
        },
        object_ids: objectIds,
        hold_token: holdToken,
      };
      dispatch(createOrder(payload))
        .unwrap()
        .then((res) => {
          const { paymentForm, orderReference } = res.data;
          // TODO: find a better way for this
          if (!paymentForm) navigate(`/orderStatus?result=success&orderReference=${orderReference}`);
          else setPaymentFormData(paymentForm);
          onCloseInternal();
        })
        .catch((err) => {
          const { errorType } = err.data;
          if (
            errorType === 'EVENT_STARTED_ERROR' ||
            errorType === 'EVENT_NOT_AVAILABLE_ERROR' ||
            errorType === 'PRICE_MISMATCH_ERROR'
          ) {
            setOrderError(errorType);
            onCloseInternal();
          } else if (errorType === 'INVALID_EMAIL_DOMAIN_ERROR') {
            const newState = Object.assign({}, formState);
            newState['email'].error = true;
            setFormState(newState);
          } else {
            toast.error('A apărut o eroare, vă rugăm să reîncărcați pagina și să încercați din nou!', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        });
    }
  };

  const onCloseInternal = () => {
    setFormState(formConfig);
    onClose();
  };

  const userRole = useMemo(() => {
    if (user && user.role === 'organizer') {
      return ' (Organizator)';
    } else if (user && user.role === 'cashier') {
      return ' (Casier)';
    } else return '';
  }, [user]);

  return (
    <Dialog fullScreen={fullScreen} open={open}>
      <DialogTitle gutterBottom>
        Finalizare comandă
        {userRole}
        <IconButton
          aria-label="close"
          onClick={onCloseInternal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography component="h2" variant="h6">
          Detalii client
        </Typography>
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <TextField
              name="firstName"
              value={formState.firstName.value}
              onChange={onInputChange}
              error={formState.firstName.error}
              helperText={formState.firstName.error ? formState.firstName.errorText : ''}
              required
              fullWidth
              variant="standard"
              type="text"
              label="Prenume"
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="lastName"
              value={formState.lastName.value}
              onChange={onInputChange}
              error={formState.lastName.error}
              helperText={formState.lastName.error ? formState.lastName.errorText : ''}
              required
              fullWidth
              variant="standard"
              requiredtype="text"
              label="Nume"
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="email"
              value={formState.email.value}
              onChange={onInputChange}
              error={formState.email.error}
              helperText={formState.email.error ? formState.email.errorText : ''}
              fullWidth
              variant="standard"
              required
              type="email"
              label="Email"
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={formState.phone.value}
              onChange={onInputChange}
              error={formState.phone.error}
              helperText={formState.phone.error ? formState.phone.errorText : ''}
              name="phone"
              required
              fullWidth
              variant="standard"
              type="tel"
              label="Număr telefon"
            ></TextField>
          </Grid>
        </Grid>
        <Typography sx={{ mt: 2 }} gutterBottom variant="h6">
          Detalii comandă
        </Typography>
        <Typography variant="subtitle1">{event.title}</Typography>
        <Typography gutterBottom variant="subtitle1">
          {`${event.location}, ${event.city}, ${dayjs(event.date).format('DD/MM/YYYY')} ${dayjs(event.date).format(
            'HH:mm'
          )}`}
        </Typography>
        <Divider />
        {seats.length ? (
          <List>
            {seats.map(({ id, section, row, seat, basePrice, discount, price, isFree, categoryLabel }) => (
              <ListItem disablePadding key={id}>
                <ListItemText primary={`${categoryLabel}`} secondary={seatInfoFormatter(section, row, seat)} />
                <ListItemSecondaryAction sx={{ right: 0 }}>
                  {isFree || discount ? (
                    <Stack>
                      <span style={{ textDecoration: 'line-through', fontSize: '0.70rem' }}>
                        {(isFree ? price : basePrice).toFixed(2) + ' Lei'}
                      </span>
                      <span style={{ color: theme.palette.error.main }}>
                        {(isFree ? 0 : price).toFixed(2) + ' Lei'}
                      </span>
                    </Stack>
                  ) : (
                    price.toFixed(2) + ' Lei'
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <Box sx={{ height: '100px' }} display="flex" alignItems="center" justifyContent="center">
            <Typography variant="subtitle1">Niciun loc selectat</Typography>
          </Box>
        )}
        <Divider />
        <Box sx={{ mt: 2, mb: 2 }}>
          <FormControl error={formState.agreeToTerms.error}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="agreeToTerms"
                    required
                    value={formState.agreeToTerms.value}
                    onChange={onInputChange}
                  />
                }
                label={
                  <p>
                    {'Am citit și sunt de-acord cu '}
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        window.open('https://poftimcultura.ro/termeni-si-conditii/');
                      }}
                    >
                      termenii și condițiile de utilizare
                    </Link>
                  </p>
                }
              />
            </FormGroup>
          </FormControl>
          <a href="https://www.librapay.ro" title="Plati online" target="_blank" rel="noreferrer">
            <img
              width="100%"
              src="https://www.librapay.ro/images/icon_securitate_LibraPay_600x60px.jpg"
              alt="Icon securitate LibraPay"
              border="0"
            />
          </a>
        </Box>
        <Grid sx={{ mt: 2 }} container justifyContent="space-between">
          <Grid item>
            <Typography component="h2" variant="h6">
              Total:
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="h2" variant="h6">
              {totalPrice && totalPrice.toFixed(2) + ' Lei'}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading || !seats.length} onClick={onFormSubmit} variant="contained">
          {user ? 'Trimite comandă' : 'Plătește'}
          {isLoading && <CircularProgress size={20} sx={{ ml: 1, color: 'white' }} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CheckoutDialog;
