import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const createNotifications = createAsyncThunk('notifications/create', (args) => {
  const { event_id, order_ids, channel, subject, body, include_attachments } = args;
  return api
    .post(`/events/${event_id}/notifications`, { order_ids, channel, subject, body, include_attachments })
    .then((res) => res.data);
});

export const fetchNotifications = createAsyncThunk('notifications/fetch', (args) => {
  const { eventId } = args;
  return api.get(`/events/${eventId}/notifications`).then((res) => res.data);
});

const initialState = {
  data: [],
  error: null,
  isLoading: false,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
    });

    builder.addCase(fetchNotifications.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(createNotifications.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(createNotifications.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(createNotifications.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export default notificationsSlice.reducer;
