import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHistory } from '../redux/slices/eventsSlice';
import { useTheme, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function EventHistoryDialog({ eventId, open, onClose }) {
  const onCloseInternal = () => {
    onClose();
  };
  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (open) {
      dispatch(fetchHistory({ eventId }));
    }
  }, [open, eventId, dispatch]);

  const history = useSelector((state) => state.events.history);

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <DialogTitle gutterBottom>
        History
        <IconButton
          aria-label="close"
          onClick={onCloseInternal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: 0 }}>
        {history.length ? (
          <Timeline
            position="right"
            sx={{
              minHeight: 500,
              minWidth: 400,
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {history.map((historyItem) => {
              const { record_type } = historyItem;
              if (record_type === 'EVENT_HISTORY') {
                const { id, created_at, date, status, plays_name, is_initial, first_name, last_name } = historyItem;
                const formattedCreatedAt = dayjs(created_at).format('DD/MM/YYYY HH:mm');
                let fullName = '';
                if (first_name || last_name) {
                  fullName = `${first_name} ${last_name}`;
                } else {
                  fullName = 'System';
                }

                const title = is_initial ? `Event created by ${fullName}` : `Event updated by ${fullName}`;
                const updates = [];

                if (plays_name) {
                  updates.push(`Play: ${plays_name || ''}`);
                }

                if (status) {
                  updates.push(`Status: ${status.replace('_', ' ')}`);
                }

                if (date) {
                  updates.push(`Date/Time: ${dayjs(date).format('DD/MM/YYYY HH:mm')}`);
                }

                const formattedUpdates = updates.join(', ');

                return (
                  <TimelineItem key={id}>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                      <Typography variant="body2">{formattedCreatedAt}</Typography>
                      <Typography component="span">{title}</Typography>
                      <Typography sx={{ fontSize: '1.1rem' }} variant="h6">
                        {formattedUpdates}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              }

              return null;
            })}
          </Timeline>
        ) : (
          <Box sx={{ minHeight: 500, minWidth: 400 }} display="flex" alignItems="center" justifyContent="center">
            <Typography sx={{ color: '#888888' }} variant="h6">
              No data to display
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
