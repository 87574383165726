export function openFileInNewTab(newTab, data, contentType) {
  const fileBlob = new Blob([data], { type: contentType });
  const url = window.URL.createObjectURL(fileBlob);
  const a = newTab.document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.click();
}

export function downloadFile(data, contentType, filename) {
  const fileBlob = new Blob([data], { type: contentType });
  const url = URL.createObjectURL(fileBlob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  if (filename) {
    a.download = filename;
  }
  document.body.appendChild(a);
  a.click();
  //window.URL.revokeObjectURL(url);
}
