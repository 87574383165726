import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const fetchPaymentPoints = createAsyncThunk('paymentPoints/list', () => {
  return api.get('/paymentPoints').then((response) => response.data);
});

export const fetchPaymentPointUsers = createAsyncThunk('paymentPointUsers/list', (args) => {
  const { paymentPointId } = args;
  return api.get(`/paymentPoints/${paymentPointId}/users`).then((response) => response.data);
});

export const fetchPaymentPointVenues = createAsyncThunk('paymentPointVenues/list', (args) => {
  const { paymentPointId } = args;
  return api.get(`/paymentPoints/${paymentPointId}/venues`).then((response) => response.data);
});

export const createPaymentPoint = createAsyncThunk('paymentPoints/create', (args, { dispatch }) => {
  const { name, representative_full_name, city } = args;
  return api
    .post('/paymentPoints', { name, representative_full_name, city })
    .then(() => dispatch(fetchPaymentPoints()));
});

export const updatePaymentPoint = createAsyncThunk('paymentPoints/update', (args, { dispatch }) => {
  const { paymentPointId, name, representative_full_name, city, status } = args;
  return api
    .put(`/paymentPoints/${paymentPointId}`, { name, representative_full_name, city, status })
    .then(() => dispatch(fetchPaymentPoints()));
});

const initialState = {
  data: [],
  paymentPointUsers: [],
  paymentPointVenues: [],
  error: null,
  isLoading: false,
  isLoadingPaymentPointUsers: false,
  isLoadingPaymentPointVenues: false,
};

const paymentPointsSlice = createSlice({
  name: 'paymentPoints',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentPoints.pending, (state, action) => {
      state.isLoading = true;
      state.data = [];
      state.error = null;
    });

    builder.addCase(fetchPaymentPoints.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
    });

    builder.addCase(fetchPaymentPoints.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchPaymentPointUsers.pending, (state, action) => {
      state.isLoadingPaymentPointUsers = true;
      state.paymentPointUsers = [];
      state.error = null;
    });

    builder.addCase(fetchPaymentPointUsers.fulfilled, (state, action) => {
      state.isLoadingPaymentPointUsers = false;
      state.paymentPointUsers = action.payload.data;
    });

    builder.addCase(fetchPaymentPointUsers.rejected, (state, action) => {
      state.isLoadingPaymentPointUsers = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchPaymentPointVenues.pending, (state, action) => {
      state.isLoadingPaymentPointVenues = true;
      state.paymentPointVenues = [];
      state.error = null;
    });

    builder.addCase(fetchPaymentPointVenues.fulfilled, (state, action) => {
      state.isLoadingPaymentPointVenues = false;
      state.paymentPointVenues = action.payload.data;
    });

    builder.addCase(fetchPaymentPointVenues.rejected, (state, action) => {
      state.isLoadingPaymentPointVenues = false;
      state.error = action.error.message;
    });

    builder.addCase(createPaymentPoint.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(createPaymentPoint.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(createPaymentPoint.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(updatePaymentPoint.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(updatePaymentPoint.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updatePaymentPoint.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default paymentPointsSlice.reducer;
