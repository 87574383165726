import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPaymentPointUsers,
  fetchPaymentPointVenues,
  fetchPaymentPoints,
} from '../redux/slices/paymentPointsSlice';
import { fetchCities } from '../redux/slices/citiesSlice';
import { fetchUsers } from '../redux/slices/usersSlice';
import { fetchVenues } from '../redux/slices/venuesSlice';
import { useTheme, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Checkbox from '@mui/material/Checkbox';
import NoData from '../components/NoData';
import PaymentPointsDrawer from '../components/PaymentPointsDrawer';
import api from '../api';

function LinkListUser({ paymentPointId, id, first_name, last_name, email, isLinked }) {
  const [isChecked, setIsChecked] = useState(false);
  const [checkedDisabled, setCheckedDisabled] = useState(false);

  useEffect(() => {
    setIsChecked(isLinked);
  }, [isLinked]);

  const onChange = (event) => {
    const checked = event.target.checked;
    setCheckedDisabled(true);
    api
      .patch(`/paymentPoints/${paymentPointId}/users`, { user_id: id, action: checked ? 'link' : 'unlink' })
      .then(() => {
        setIsChecked(checked);
        setCheckedDisabled(false);
      })
      .catch(() => {
        toast.error('An error occured, please try again!', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setCheckedDisabled(false);
      });
  };

  return (
    <ListItem key={id} divider>
      <ListItemText primary={`${first_name} ${last_name}`} secondary={email} />
      <ListItemSecondaryAction>
        <Checkbox disabled={checkedDisabled} checked={isChecked} onChange={onChange} />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function LinkUserDialog({ open, onClose, paymentPoint }) {
  const onCloseInternal = () => {
    onClose();
  };

  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const usersError = useSelector((state) => state.users.error);

  const users = useSelector((state) => {
    return state.users.data.map((user) => {
      const isLinked = !!state.paymentPoints.paymentPointUsers.find((linkedUser) => linkedUser.users_id === user.id);
      return { ...user, isLinked };
    });
  });

  useEffect(() => {
    if (usersError) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [usersError]);

  useEffect(() => {
    if (open) {
      dispatch(fetchUsers({ role: 'CASHIER' }));
      // TODO: what happens here if it fails?!
      dispatch(fetchPaymentPointUsers({ paymentPointId: paymentPoint.id }));
    }
  }, [dispatch, open, paymentPoint]);

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <DialogTitle gutterBottom>
        {paymentPoint.name} Users
        <IconButton
          aria-label="close"
          onClick={onCloseInternal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: 0 }}>
        {users.length ? (
          <List sx={{ minHeight: 500, minWidth: 400 }}>
            {users.map((user) => (
              <LinkListUser key={user.id} paymentPointId={paymentPoint.id} {...user} />
            ))}
          </List>
        ) : (
          <Box sx={{ minHeight: 500, minWidth: 400 }} display="flex" alignItems="center" justifyContent="center">
            <Typography sx={{ color: '#888888' }} variant="h6">
              No data to display
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

function LinkListVenue({ paymentPointId, id, name, city, isLinked }) {
  const [isChecked, setIsChecked] = useState(false);
  const [checkedDisabled, setCheckedDisabled] = useState(false);

  useEffect(() => {
    setIsChecked(isLinked);
  }, [isLinked]);

  const onChange = (event) => {
    const checked = event.target.checked;
    setCheckedDisabled(true);
    api
      .patch(`/paymentPoints/${paymentPointId}/venues`, { venue_id: id, action: checked ? 'link' : 'unlink' })
      .then(() => {
        setIsChecked(checked);
        setCheckedDisabled(false);
      })
      .catch(() => {
        toast.error('An error occured, please try again!', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setCheckedDisabled(false);
      });
  };

  return (
    <ListItem key={id} divider>
      <ListItemText primary={name} secondary={city} />
      <ListItemSecondaryAction>
        <Checkbox disabled={checkedDisabled} checked={isChecked} onChange={onChange} />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function LinkVenueDialog({ open, onClose, paymentPoint }) {
  const onCloseInternal = () => {
    onClose();
  };

  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const venuesError = useSelector((state) => state.venues.error);

  const venues = useSelector((state) => {
    return state.venues.data.map((venue) => {
      const isLinked = !!state.paymentPoints.paymentPointVenues.find(
        (linkedVenue) => linkedVenue.venues_id === venue.id
      );
      return { ...venue, isLinked };
    });
  });

  useEffect(() => {
    if (venuesError) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [venuesError]);

  useEffect(() => {
    if (open) {
      dispatch(fetchVenues({ city: paymentPoint.city }));
      // TODO: what happens here if it fails?!
      dispatch(fetchPaymentPointVenues({ paymentPointId: paymentPoint.id }));
    }
  }, [dispatch, open, paymentPoint]);

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <DialogTitle gutterBottom>
        {paymentPoint.name} Venues
        <IconButton
          aria-label="close"
          onClick={onCloseInternal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: 0 }}>
        {venues.length ? (
          <List sx={{ minHeight: 500, minWidth: 400 }}>
            {venues.map((venue) => (
              <LinkListVenue key={venue.id} paymentPointId={paymentPoint.id} {...venue} />
            ))}
          </List>
        ) : (
          <Box sx={{ minHeight: 500, minWidth: 400 }} display="flex" alignItems="center" justifyContent="center">
            <Typography sx={{ color: '#888888' }} variant="h6">
              No data to display
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

function PaymentPointRow({ cities, paymentPoint }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openEditUsers, setOpenEditUsers] = useState(false);
  const [openEditVenues, setOpenEditVenues] = useState(false);

  const toggleEdit = () => setOpenEdit((openEdit) => !openEdit);
  const toggleEditUsers = () => setOpenEditUsers((openEditUsers) => !openEditUsers);
  const toggleEditVenues = () => setOpenEditVenues((openEditVenues) => !openEditVenues);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { id, created_at, name, representative_full_name, city, status } = paymentPoint;
  return (
    <TableRow
      key={id}
      sx={{
        textAlign: 'right',
        '&:last-child td, &:last-child th': { borderBottom: 0 },
        '&:nth-of-type(odd)': (theme) => ({
          backgroundColor: theme.palette.action.hover,
        }),
      }}
    >
      <TableCell>{dayjs(created_at).format('DD/MM/YYYY HH:mm')}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{representative_full_name}</TableCell>
      <TableCell>{city}</TableCell>
      <TableCell>
        {status === 'ACTIVE' ? (
          <Chip sx={{ mt: 1, borderRadius: 1 }} size="small" label={`${status}`} variant="outlined" color="success" />
        ) : (
          <Chip sx={{ mt: 1, borderRadius: 1 }} size="small" label={`${status}`} variant="outlined" color="error" />
        )}
      </TableCell>
      <TableCell align="right">
        <IconButton size="small" onClick={handleMenu}>
          <MoreHorizIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!anchorEl}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              toggleEdit();
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              toggleEditUsers();
            }}
          >
            Users
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              toggleEditVenues();
            }}
          >
            Venues
          </MenuItem>
        </Menu>
      </TableCell>
      <PaymentPointsDrawer open={openEdit} cities={cities} paymentPoint={paymentPoint} onClose={toggleEdit} />
      <LinkUserDialog open={openEditUsers} paymentPoint={paymentPoint} onClose={toggleEditUsers} />
      <LinkVenueDialog open={openEditVenues} paymentPoint={paymentPoint} onClose={toggleEditVenues} />
    </TableRow>
  );
}

function PaymentPoints() {
  const dispatch = useDispatch();

  const [openCreate, setOpenCreate] = useState(false);

  const paymentPoints = useSelector(({ paymentPoints: { data } }) => data);

  const cityData = useSelector((state) => state.cities.data);
  const cityDataError = useSelector((state) => state.cities.error);

  const paymentPointsError = useSelector((state) => state.paymentPoints.error);

  const toggleCreate = () => setOpenCreate((openCreate) => !openCreate);

  useEffect(() => {
    dispatch(fetchPaymentPoints());
    dispatch(fetchCities());
  }, [dispatch]);

  useEffect(() => {
    if (paymentPointsError || cityDataError) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [paymentPointsError, cityDataError]);

  return (
    <div className="payment-points">
      <Grid sx={{ mb: 4 }} container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3">Payment Points</Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={toggleCreate}>
            Add Payment Point
          </Button>
        </Grid>
      </Grid>
      {paymentPoints.length ? (
        <TableContainer sx={{ mt: 4 }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Created</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Representative</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentPoints.map((paymentPoint) => (
                <PaymentPointRow key={paymentPoint.id} cities={cityData} paymentPoint={paymentPoint} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoData />
      )}
      <PaymentPointsDrawer open={openCreate} cities={cityData} onClose={toggleCreate} />
    </div>
  );
}

export default PaymentPoints;
