import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrganizers } from '../redux/slices/organizersSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import CardActionArea from '@mui/material/CardActionArea';
import OrganizerDrawer from '../components/OrganizerDrawer';

function Organizers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openCreate, setOpenCreate] = useState(false);

  const organizersData = useSelector((state) => state.organizers.data);
  const error = useSelector((state) => state.organizers.error);

  const toggleOpenCreate = () => {
    setOpenCreate((openCreate) => !openCreate);
  };

  useEffect(() => {
    dispatch(fetchOrganizers());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [error]);

  return (
    <div className="organizers">
      <Grid container spacing={4}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3">Organizers</Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={toggleOpenCreate}>
              Add organizer
            </Button>
          </Grid>
        </Grid>
        {organizersData.map(({ id, name }) => (
          <Grid key={id} item>
            <Card sx={{ width: '300px' }}>
              <CardHeader
                sx={{
                  '& .MuiCardHeader-content': {
                    overflow: 'hidden',
                  },
                }}
                titleTypographyProps={{ noWrap: true }}
                title={name}
              />
              <Divider />
              <CardActionArea onClick={() => navigate(`${id}`)}>
                <Skeleton variant="rectangular" height="400px" />
              </CardActionArea>
              {/*
                <CardMedia
                  component="img"
                  image="https://comedie.ro/wp-content/uploads/2023/01/doi-pe-o-banca.jpg"
                  alt="Play thumbnail"
                />
        */}
            </Card>
          </Grid>
        ))}
      </Grid>
      <OrganizerDrawer open={openCreate} onClose={toggleOpenCreate} />
    </div>
  );
}

export default Organizers;
