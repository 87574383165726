import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import NoData from './NoData';

export default function EventsNotificationsTable({ events, selected, setSelected }) {
  const user = useSelector((state) => state.auth.user);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = events.filter((n) => n.not_billed_comission > 0).map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleEventClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  return events.length ? (
    <TableContainer sx={{ marginTop: 2 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {user.role === 'admin' && (
              <TableCell padding="checkbox">
                <Checkbox
                  disabled={events.every((n) => n.not_billed_comission <= 0)}
                  indeterminate={
                    selected.length > 0 && selected.length < events.filter((n) => n.not_billed_comission > 0).length
                  }
                  checked={
                    events.length > 0 &&
                    selected.length > 0 &&
                    selected.length === events.filter((n) => n.not_billed_comission > 0).length
                  }
                  onChange={handleSelectAllClick}
                  color="primary"
                />
              </TableCell>
            )}
            <TableCell>ID</TableCell>
            {user.role !== 'organizer' && <TableCell>Organizer</TableCell>}
            <TableCell>Company</TableCell>
            <TableCell>Event</TableCell>
            <TableCell align="center">Payout ID</TableCell>
            <TableCell align="center">Comission Status</TableCell>
            <TableCell align="right">Not Billed Comission</TableCell>
            <TableCell align="right">Billed Comission</TableCell>
            <TableCell align="right">Comission</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map(
            ({
              id,
              date,
              organizer,
              company,
              name,
              city,
              billed_comission,
              not_billed_comission,
              comission,
              transfers,
            }) => {
              const comissionPaid = transfers.length ? transfers.every((t) => t.is_comission_paid) : false;
              const transferIds = transfers.length ? transfers.map((t) => t.id).join(', ') : '-';
              return (
                <TableRow
                  key={id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:nth-of-type(odd)': (theme) => ({
                      backgroundColor: theme.palette.action.hover,
                    }),
                  }}
                >
                  {user.role === 'admin' && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        disabled={not_billed_comission <= 0}
                        checked={isSelected(id)}
                        onClick={(event) => handleEventClick(event, id)}
                        color="primary"
                      />
                    </TableCell>
                  )}
                  <TableCell>{id}</TableCell>
                  {user.role !== 'organizer' && <TableCell>{organizer}</TableCell>}
                  <TableCell>{company}</TableCell>
                  <TableCell>{`${dayjs(date).format('DD/MM/YYYY HH:mm')} - ${name} - ${city}`}</TableCell>
                  <TableCell align="center">{transferIds}</TableCell>
                  <TableCell align="center">
                    {comissionPaid ? (
                      <Chip
                        sx={{ mt: 1, borderRadius: 1 }}
                        size="small"
                        label="PAID"
                        variant="outlined"
                        color="success"
                      />
                    ) : (
                      <Chip
                        sx={{ mt: 1, borderRadius: 1 }}
                        size="small"
                        label="UNPAID"
                        variant="outlined"
                        color="error"
                      />
                    )}
                  </TableCell>
                  <TableCell align="right">{not_billed_comission} Lei</TableCell>
                  <TableCell align="right">{billed_comission} Lei</TableCell>
                  <TableCell align="right">{comission} Lei</TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <NoData />
  );
}
