import { SeatsioSeatingChart } from '@seatsio/seatsio-react';
import { toast } from 'react-toastify';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEventLight } from '../redux/slices/eventsSlice';
import {
  addSeat,
  removeSeat,
  setHoldToken,
  clearSeats,
  setOpenCheckout,
  addSeatNPlusOne,
  removeSeatNPlusOne,
  PromotionType,
} from '../redux/slices/cartSlice';
import { useMediaQuery, useTheme, Box, Typography } from '@mui/material';
import OrderErrorDialog from '../components/OrderErrorDialog';
import CheckoutDialog from '../components/CheckoutDialog';
import MobileDrawer from '../components/MobileDrawer';
import DesktopDrawer from '../components/DesktopDrawer';
import NPlusOneFreeDialog from '../components/NPlusOneFreeDialog';
import ChartLegend from '../components/ChartLegend';

const priceFormatter = (price) => price.toFixed(2) + ' Lei';

const drawerWidth = 340;

function SeatSelector() {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openNPlusOneDialog, setOpenNPlusOneDialog] = useState(false);

  const chartRef = useRef(null);

  const event = useSelector(({ events: { selectedEvent } }) => selectedEvent);
  //const isLoading = useSelector(({ events: { isLoading } }) => isLoading);
  const error = useSelector(({ events: { error } }) => error);

  const seats = useSelector(({ cart: { seats } }) => seats);
  const openCheckout = useSelector(({ cart: { openCheckout } }) => openCheckout);
  const totalPrice = useSelector(({ cart: { totalPrice } }) => totalPrice);

  const [paymentFormData, setPaymentFormData] = useState({ postAction: '', fields: [] });
  const [orderError, setOrderError] = useState(null);
  const paymentFormRef = useRef(null);

  const { seatsio_event_id, status, is_one_plus_one_free, is_three_plus_one_free } = event;

  const promotionType = useMemo(() => {
    if (is_one_plus_one_free) {
      return PromotionType.ONE_PLUS_ONE;
    } else if (is_three_plus_one_free) {
      return PromotionType.THREE_PLUS_ONE;
    } else {
      return PromotionType.NONE;
    }
  }, [is_one_plus_one_free, is_three_plus_one_free]);

  useEffect(() => {
    if (error) {
      toast.error('A apărut o eroare, vă rugăm să reîncărcați pagina și să încercați din nou!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [error]);

  useEffect(() => {
    if (promotionType !== PromotionType.NONE && status === 'SELLING') {
      setOpenNPlusOneDialog(true);
    }
    return () => {
      setOpenNPlusOneDialog(false);
    };
  }, [promotionType, status]);

  useEffect(() => {
    if (eventId) {
      dispatch(fetchEventLight({ eventId }));
    }
    // TODO: possible bug here, fuck knows...
    dispatch(clearSeats());
  }, [dispatch, eventId]);

  useEffect(() => {
    if (paymentFormData.postAction && paymentFormData.fields.length) {
      paymentFormRef.current.submit();
    }
  }, [paymentFormData]);

  const prices = useMemo(
    () =>
      event.tickets.map(({ categories_id, price, discount }) => {
        let actualPrice = price;
        if (discount) {
          actualPrice -= price * discount;
        }
        return { category: categories_id, price: actualPrice };
      }),
    [event]
  );

  const onChartRendered = (chart) => {
    chartRef.current = chart;
  };

  const onObjectSelected = (selectedObj) => {
    const {
      seatId,
      labels,
      pricing: { price },
      category: { key, label, color },
    } = selectedObj;

    //const [section, row, seat] = seatId.split('-');
    const { section, parent: row, own: seat } = labels;

    const priceInfo = event.tickets.find((pricing) => pricing.categories_id === key);

    const seatObj = {
      id: seatId,
      section,
      row,
      seat,
      basePrice: priceInfo.price,
      discount: priceInfo.discount,
      price,
      categoryId: key,
      categoryLabel: label,
      categoryColor: color,
    };

    if (promotionType !== PromotionType.NONE) {
      dispatch(addSeatNPlusOne({ seat: seatObj, promotionType }));
    } else {
      dispatch(addSeat(seatObj));
    }
  };

  const onObjectDeselected = (deselectedObj) => {
    const { id } = deselectedObj;

    if (promotionType !== PromotionType.NONE) {
      dispatch(removeSeatNPlusOne({ id, promotionType }));
    } else {
      dispatch(removeSeat(id));
    }
  };

  const onRemoveAll = () => {
    if (!chartRef || !chartRef.current) return;
    chartRef.current.clearSelection();
  };

  const onRemove = (seatId) => {
    if (!chartRef || !chartRef.current) return;
    chartRef.current.deselectObjects([seatId]);
  };

  const onHoldTokenExpired = () => {
    dispatch(clearSeats());
  };

  const onSessionInitialized = ({ token }) => {
    dispatch(setHoldToken(token));
  };

  const drawerProps = {
    drawerWidth,
    seats,
    totalPrice,
    onRemove,
    onRemoveAll,
  };

  return (
    <div className="seat-selector">
      <div style={{ position: 'relative', height: '100vh', width: `calc(100vw - ${!isMobile ? drawerWidth : 0}px)` }}>
        {(status === 'NOT_SELLING' || status === 'ARCHIVED' || status === 'DELETED') && (
          <Box sx={{ height: '100%', width: '100%' }} display="flex" alignItems="center" justifyContent="center">
            <Typography variant="h4">Eveniment indisponibil</Typography>
          </Box>
        )}
        {seatsio_event_id && status === 'SELLING' && (
          <>
            <ChartLegend categories={event.tickets} />
            <SeatsioSeatingChart
              //selectionValidators={[{ type: 'noOrphanSeats' }]}
              objectWithoutPricingSelectable={false}
              objectTooltip={{ confirmSelectionOnMobile: false }}
              showZoomOutButtonOnMobile={false}
              legend={{ hideNonSelectableCategories: true }}
              workspaceKey={process.env.REACT_APP_SEATSIO_PUBLIC_WORKSPACE_KEY}
              event={seatsio_event_id}
              session="continue"
              onChartRendered={onChartRendered}
              onSessionInitialized={onSessionInitialized}
              onHoldTokenExpired={onHoldTokenExpired}
              pricing={prices}
              priceFormatter={priceFormatter}
              showFullScreenButton={false}
              onObjectSelected={onObjectSelected}
              onObjectDeselected={onObjectDeselected}
              region={process.env.REACT_APP_SEATSIO_REGION}
              colorScheme="dark"
              language="ro"
            />
          </>
        )}
      </div>
      {!isMobile ? <DesktopDrawer {...drawerProps} /> : <MobileDrawer {...drawerProps} />}
      <CheckoutDialog
        open={openCheckout}
        setOrderError={setOrderError}
        setPaymentFormData={setPaymentFormData}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') dispatch(setOpenCheckout(false));
        }}
        seats={seats}
        event={event}
      />
      <OrderErrorDialog open={!!orderError} errorType={orderError} />
      <NPlusOneFreeDialog
        promotionType={promotionType}
        open={openNPlusOneDialog}
        onConfirm={() => setOpenNPlusOneDialog(false)}
      />
      <form ref={paymentFormRef} id="libra-payment-form" action={paymentFormData.postAction} method="post">
        {paymentFormData.fields.map(({ name, value }) => (
          <input type="hidden" key={name} name={name} value={value} />
        ))}
      </form>
    </div>
  );
}

export default SeatSelector;
