import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function NoData() {
  return (
    <Paper sx={{ mt: 2, mb: 2, height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Typography sx={{ color: '#8080806b' }} variant="subtitle1">
        No data to display
      </Typography>
    </Paper>
  );
}
