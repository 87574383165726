import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlays } from '../redux/slices/playsSlice';
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabPanel from '../components/TabPanel';
import PlaysDrawer from '../components/PlaysDrawer';

function Plays() {
  const dispatch = useDispatch();
  const [openCreate, setOpenCreate] = useState(false);
  const [editPlay, setEditPlay] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(0);

  const isLoading = useSelector((state) => state.plays.isLoading);
  const playsData = useSelector((state) => state.plays.data);
  const error = useSelector((state) => state.plays.error);

  const notArchived = useMemo(() => playsData.filter(({ status }) => status === 'ACTIVE'), [playsData]);
  const archived = useMemo(() => playsData.filter(({ status }) => status === 'ARCHIVED').reverse(), [playsData]);

  useEffect(() => {
    const selectedTabId = searchParams.get('selectedTabId');
    if (selectedTabId) {
      setTabIndex(+selectedTabId);
    } else {
      searchParams.set('selectedTabId', 0);
      setSearchParams(searchParams);
    }
  }, [setTabIndex, setSearchParams, searchParams]);

  const onDrawerClose = () => {
    setOpenCreate(false);
    setEditPlay(null);
  };

  const toggleOpenUpdate = (play) => {
    setEditPlay(play);
  };

  const toggleOpenCreate = () => {
    setOpenCreate(true);
  };

  useEffect(() => {
    dispatch(fetchPlays());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [error]);

  return (
    <div className="plays">
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3">Plays</Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={toggleOpenCreate}>
            Add play
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabIndex}
          onChange={(_, newValue) => {
            searchParams.set('selectedTabId', newValue);
            setSearchParams(searchParams);
            setTabIndex(newValue);
          }}
        >
          <Tab label="Active" />
          <Tab label="Archived" />
        </Tabs>
      </Box>
      <TabPanel disabled={isLoading} value={tabIndex} index={0}>
        <Grid container spacing={4}>
          {notArchived.map((play) => (
            <Grid key={play.id} item>
              <Card sx={{ width: '300px', minHeight: '470px' }}>
                <CardHeader
                  sx={{
                    '& .MuiCardHeader-content': {
                      overflow: 'hidden',
                    },
                  }}
                  titleTypographyProps={{ noWrap: true }}
                  title={play.name}
                  action={
                    <IconButton onClick={() => toggleOpenUpdate(play)}>
                      <EditIcon />
                    </IconButton>
                  }
                />
                <Divider />
                {!play.portrait_image_url ? (
                  <Skeleton variant="rectangular" height="420px" />
                ) : (
                  <CardMedia component="img" image={play.portrait_image_url} />
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      </TabPanel>
      <TabPanel disabled={isLoading} value={tabIndex} index={1}>
        <Grid container spacing={4}>
          {archived.map((play) => (
            <Grid key={play.id} item>
              <Card sx={{ width: '300px', minHeight: '470px' }}>
                <CardHeader
                  sx={{
                    '& .MuiCardHeader-content': {
                      overflow: 'hidden',
                    },
                  }}
                  titleTypographyProps={{ noWrap: true }}
                  title={play.name}
                  action={
                    <IconButton onClick={() => toggleOpenUpdate(play)}>
                      <EditIcon />
                    </IconButton>
                  }
                />
                <Divider />
                {!play.portrait_image_url ? (
                  <Skeleton variant="rectangular" height="420px" />
                ) : (
                  <CardMedia component="img" image={play.portrait_image_url} />
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      </TabPanel>

      <PlaysDrawer open={openCreate || !!editPlay} play={editPlay} onClose={onDrawerClose} />
    </div>
  );
}

export default Plays;
