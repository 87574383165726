import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchArtists } from '../redux/slices/artistsSlice';
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ArtistsDrawer from '../components/ArtistsDrawer';

function Artists() {
  const dispatch = useDispatch();
  const [openCreate, setOpenCreate] = useState(false);
  const [editArtist, setEditArtist] = useState(null);

  const artistsData = useSelector((state) => state.artists.data);
  const error = useSelector((state) => state.artists.error);

  const onDrawerClose = () => {
    setOpenCreate(false);
    setEditArtist(null);
  };

  const toggleOpenUpdate = (artist) => {
    setEditArtist(artist);
  };

  const toggleOpenCreate = () => {
    setOpenCreate(true);
  };

  useEffect(() => {
    dispatch(fetchArtists());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [error]);

  return (
    <div className="artists">
      <Grid container spacing={4}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3">Artists</Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={toggleOpenCreate}>
              Add artist
            </Button>
          </Grid>
        </Grid>
        {artistsData.map((artist) => (
          <Grid key={artist.id} item>
            <Card sx={{ width: '300px', minHeight: '360px' }}>
              <CardHeader
                sx={{
                  '& .MuiCardHeader-content': {
                    overflow: 'hidden',
                  },
                }}
                titleTypographyProps={{ noWrap: true }}
                title={`${artist.first_name} ${artist.last_name}`}
                action={
                  <IconButton onClick={() => toggleOpenUpdate(artist)}>
                    <EditIcon />
                  </IconButton>
                }
              />
              <Divider />
              {!artist.profile_image_url ? (
                <Skeleton variant="rectangular" height="300px" />
              ) : (
                <CardMedia component="img" image={artist.profile_image_url} />
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
      <ArtistsDrawer open={openCreate || !!editArtist} artist={editArtist} onClose={onDrawerClose} />
    </div>
  );
}

export default Artists;
