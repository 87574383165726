import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createArtist, updateArtist } from '../redux/slices/artistsSlice';
import { toast } from 'react-toastify';
import validator from 'validator';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FileDropzone from './FileDropzone';

// This is in bytes
const MAX_IMAGE_SIZE = 10000000;
const ACCEPTED_FORMATS = { 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'] };

const empty = (value) => !validator.isEmpty(value, { ignore_whitespace: true });

function ArtistsDrawer({ open, onClose, artist }) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.artists.isLoading);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [selectedProfileUrl, setSelectedProfileUrl] = useState('');
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [description, setDescription] = useState('');
  const [index, setIndex] = useState(true);

  const createDisabled = useMemo(
    () => isLoading || !empty(firstName) || description.length > 512 || !empty(lastName) || !selectedProfile,
    [isLoading, firstName, description, lastName, selectedProfile]
  );
  const updateDisabled = useMemo(
    () => isLoading || !empty(firstName) || !empty(lastName) || description.length > 512,
    [isLoading, firstName, lastName, description]
  );

  const onProfile = useCallback(
    (file) => {
      setSelectedProfile(file);
    },
    [setSelectedProfile]
  );

  const onDrawerClose = () => {
    onClose();
    setFirstName('');
    setLastName('');
    setDescription('');
    setSelectedProfileUrl('');
    setSelectedProfile(null);
    setIndex(true);
  };

  useEffect(() => {
    if (artist) {
      const { first_name, last_name, description, profile_image_url, index_for_search } = artist;
      setFirstName(first_name);
      setLastName(last_name);
      setDescription(description || '');
      setSelectedProfileUrl(profile_image_url);
      setIndex(Boolean(index_for_search));
    }
  }, [artist]);

  const onCreateArtist = () => {
    const payload = {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      description: description.trim(),
      profile: selectedProfile,
      index_for_search: index,
    };
    dispatch(createArtist(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('Artist created successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        onDrawerClose();
      }
    });
  };

  const onUpdateArtist = () => {
    const payload = {
      artistId: artist.id,
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      description: description.trim(),
      profile: selectedProfile,
      index_for_search: index,
    };
    dispatch(updateArtist(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('Artist updated successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        onDrawerClose();
      }
    });
  };

  return (
    <Drawer anchor="right" open={open}>
      <Toolbar />
      <Toolbar>
        <Typography variant="button">{!artist ? 'Create artist' : 'Edit artist'}</Typography>
      </Toolbar>
      <Divider />
      <Box sx={{ width: 400, pl: 2, pr: 2 }}>
        <Box component="form">
          <TextField
            required
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            name="first_name"
            label="Fist Name"
          />
          <TextField
            required
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            name="last_name"
            label="Last Name"
          />
          <TextField
            multiline
            minRows={5}
            maxRows={10}
            value={description}
            onChange={(event) => setDescription(event.target.value.replace('\n', '\r\n'))}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            name="description"
            label="Description"
            helperText="Max length is 512 characters"
          />
          <Box mt={2} mb={2}>
            <Typography gutterBottom variant="subtitle1">
              Profile
            </Typography>
            <FileDropzone
              sx={{ height: 300 }}
              previewUrl={selectedProfileUrl}
              preview
              fileTypes={ACCEPTED_FORMATS}
              maxSize={MAX_IMAGE_SIZE}
              onDrop={onProfile}
            />
          </Box>
          <Typography variant="body2">Max size: 10MB</Typography>
          <Typography variant="body2">File format: JPEG,JPG,PNG</Typography>
          <FormGroup sx={{ display: 'block', mt: 2 }}>
            <FormControlLabel
              control={<Switch checked={index} onChange={(event) => setIndex(event.target.checked)} />}
              label="Index in main site"
            />
          </FormGroup>
        </Box>
      </Box>
      <Box sx={{ p: 2, mt: 'auto' }}>
        <Grid columnSpacing={2} container>
          <Grid xs={6} item>
            <Button fullWidth variant="outlined" onClick={onDrawerClose}>
              Cancel
            </Button>
          </Grid>
          <Grid xs={6} item>
            <Button
              disabled={!artist ? createDisabled : updateDisabled}
              fullWidth
              variant="contained"
              onClick={!artist ? onCreateArtist : onUpdateArtist}
            >
              {!artist ? 'Create Artist' : 'Update Artist'}
              {isLoading && <CircularProgress size={24} sx={{ ml: 1, color: 'white' }} />}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}

export default ArtistsDrawer;
