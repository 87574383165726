import { useDropzone } from 'react-dropzone';
import { useEffect, useCallback, useMemo, useState } from 'react';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function FileDropzone({ sx, previewUrl, preview = false, fileTypes, maxSize, onDrop }) {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');

  useEffect(() => {
    if (previewUrl) {
      setFileUrl(previewUrl);
    }
  }, [previewUrl]);

  useEffect(() => {
    return () => {
      if (fileUrl) URL.revokeObjectURL(fileUrl);
    };
  }, [fileUrl]);

  const onDropInternal = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length) {
        const file = acceptedFiles[0];
        setFile(file);
        setFileUrl(URL.createObjectURL(file));
        onDrop(file);
      }
    },
    [setFile, setFileUrl, onDrop]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    autoFocus: true,
    onDrop: onDropInternal,
    // TODO: handle this better someday
    onError: (err) => console.error(err),
    maxFiles: 1,
    maxSize,
    multiple: false,
    accept: fileTypes,
  });

  const theme = useTheme();

  const dropAreaStyle = useMemo(() => {
    const border = isDragAccept
      ? { borderColor: theme.palette.success.main }
      : isDragReject
      ? { borderColor: theme.palette.error.main }
      : { borderColor: '#8a8a8a' };

    const common = {
      width: '100%',
      height: '100%',
      ...(preview ? { backgroundImage: `url(${fileUrl})` } : {}),
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      padding: '8px',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: '2px',
      borderRadius: '3%',
      borderStyle: 'dashed',
    };

    return {
      ...common,
      ...border,
      ...(sx || {}),
    };
  }, [preview, sx, theme, isDragAccept, isDragReject, fileUrl]);

  return (
    <div
      onLoad={() => {
        if (fileUrl) URL.revokeObjectURL(fileUrl);
      }}
      {...getRootProps()}
      style={dropAreaStyle}
    >
      <input {...getInputProps()} />
      {!file &&
        !fileUrl &&
        (isDragActive ? (
          <Typography variant="body2">Drop file here...</Typography>
        ) : (
          <Typography variant="body2">Drag 'n' drop file here, or click to select it</Typography>
        ))}
      {file && !preview && file.name}
    </div>
  );
}
