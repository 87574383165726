const seatInfoFormatter = (section, row, seat) => {
  const secRowSeat = `Sector ${section}, rând ${row}, loc ${seat}`;
  const secSeat = `Sector ${section}, loc ${seat}`;
  const rowSeat = `Rând ${row}, loc ${seat}`;
  const seatStr = `Loc ${seat}`;

  if (section && row && seat) return secRowSeat;
  else if (row && seat) return rowSeat;
  else if (section && seat) return secSeat;
  else return seatStr;
};

export const seatInfoFormatterEn = (section, row, seat) => {
  const secRowSeat = `Section ${section}, row ${row}, seat ${seat}`;
  const secSeat = `Section ${section}, seat ${seat}`;
  const rowSeat = `Row ${row}, seat ${seat}`;
  const seatStr = `Seat ${seat}`;

  if (section && row && seat) return secRowSeat;
  else if (row && seat) return rowSeat;
  else if (section && seat) return secSeat;
  else return seatStr;
};

export const seatIdToSectionParentOwn = (id, section, parent, own) => {
  if (!id) return;
  // NOTE: this crap is here only for backwards compatibility, maybe in the future it can be removed
  let [parsedSect, parsedParen, parsedOwn] = id.split('-');
  if (!parsedOwn) {
    parsedOwn = parsedParen;
    parsedParen = null;
  }

  if (section || parent || own) {
    parsedSect = section;
    parsedParen = parent;
    parsedOwn = own;
  }

  return seatInfoFormatterEn(parsedSect, parsedParen, parsedOwn);
};

export default seatInfoFormatter;
