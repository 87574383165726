import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const fetchChartTemplates = createAsyncThunk('charts/listTemplates', () => {
  return api.get('/charts/templates').then((response) => response.data);
});

export const fetchCharts = createAsyncThunk('charts/list', (args = {}) => {
  const { status, venueId } = args;
  return api
    .get(
      `/charts?status=${status ? encodeURIComponent(status) : ''}&venueId=${venueId ? encodeURIComponent(venueId) : ''}`
    )
    .then(({ data: { data } }) => {
      return Promise.all(
        data.map(({ id, ...rest }) =>
          api.get(`/charts/${id}/categories`).then(({ data }) => ({ id, ...rest, categories: data.data }))
        )
      );
    })
    .then((response) => ({ data: response }));
});

export const createChart = createAsyncThunk('charts/create', (args, { dispatch }) => {
  const { venue_id, seatsio_chart_id, name, address, coordinate, categories } = args;
  return api
    .post('/charts', { venue_id, seatsio_chart_id, name, address, coordinate, categories })
    .then(() => dispatch(fetchCharts({ venueId: venue_id })));
});

export const updateChart = createAsyncThunk('charts/update', (args, { dispatch }) => {
  const { venueId, chartId, name, address, coordinate, status, categories } = args;
  return api
    .put(`/charts/${chartId}`, { name, address, coordinate, status, categories })
    .then(() => dispatch(fetchCharts({ venueId })));
});

const initialState = {
  chartTemplates: [],
  data: [],
  error: null,
  isLoading: false,
};

const chartsSlice = createSlice({
  name: 'charts',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchChartTemplates.pending, (state, action) => {
      state.isLoading = true;
      state.chartTemplates = [];
      state.error = null;
    });

    builder.addCase(fetchChartTemplates.fulfilled, (state, action) => {
      state.isLoading = false;
      state.chartTemplates = action.payload.data;
    });

    builder.addCase(fetchChartTemplates.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchCharts.pending, (state, action) => {
      state.isLoading = true;
      state.data = [];
      state.error = null;
    });

    builder.addCase(fetchCharts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
    });

    builder.addCase(fetchCharts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(createChart.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(createChart.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(createChart.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateChart.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(updateChart.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateChart.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default chartsSlice.reducer;
