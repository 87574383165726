import { toast } from 'react-toastify';
import validator from 'validator';
import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createUser, updateUser } from '../redux/slices/usersSlice';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const empty = (value) => validator.isEmpty(value, { ignore_whitespace: true });

function UsersDrawer({ open, onClose, user }) {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  useEffect(() => {
    if (user) {
      const { email, first_name, last_name, role, status } = user;
      setEmail(email);
      setFirstName(first_name);
      setLastName(last_name);
      setSelectedRole(role);
      setSelectedStatus(status);
    }
  }, [user]);

  const createDisabled = useMemo(
    () =>
      !!(
        empty(firstName) ||
        empty(lastName) ||
        empty(selectedRole) ||
        !validator.isEmail(email) ||
        !validator.isStrongPassword(password) ||
        password !== verifyPassword
      ),
    [firstName, lastName, email, password, verifyPassword, selectedRole]
  );

  const updateDisabled = useMemo(
    () =>
      !!(
        empty(firstName) ||
        empty(lastName) ||
        empty(selectedRole) ||
        !validator.isEmail(email) ||
        (password && !validator.isStrongPassword(password)) ||
        (password && password !== verifyPassword)
      ),
    [firstName, lastName, email, password, verifyPassword, selectedRole]
  );

  const onDrawerClose = () => {
    onClose();
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setVerifyPassword('');
    setSelectedRole('');
    setSelectedStatus('');
  };

  const onCreateUser = () => {
    const payload = {
      email,
      first_name: firstName,
      last_name: lastName,
      role: selectedRole,
      status: selectedStatus,
      password: password,
      verify_password: verifyPassword,
    };

    dispatch(createUser(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('User created successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        onDrawerClose();
      }
    });
  };

  const onUpdateUser = () => {
    const payload = {
      user_id: user.id,
      email,
      first_name: firstName,
      last_name: lastName,
      role: selectedRole,
      status: selectedStatus,
      password: password,
      verify_password: verifyPassword,
    };

    dispatch(updateUser(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('User updated successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        onDrawerClose();
      }
    });
  };

  return (
    <Drawer anchor="right" open={open}>
      <Toolbar />
      <Toolbar>
        <Typography variant="button">{!user ? 'Create user' : 'Edit user'}</Typography>
      </Toolbar>
      <Divider />
      <Box sx={{ width: 400, pr: 2, pl: 2 }}>
        <TextField
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
          variant="standard"
          margin="normal"
          size="small"
          required={!user}
          fullWidth
          type="text"
          label="First name"
        />
        <TextField
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
          variant="standard"
          margin="normal"
          size="small"
          required={!user}
          fullWidth
          type="text"
          label="Last name"
        />
        <TextField
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          variant="standard"
          margin="normal"
          size="small"
          required={!user}
          fullWidth
          type="email"
          label="Email"
        />
        <TextField
          value={password}
          helperText="Min length 8, 1 upper, 1 lower, 1 number and 1 symbol"
          onChange={(event) => setPassword(event.target.value)}
          variant="standard"
          margin="normal"
          size="small"
          required={!user}
          fullWidth
          type="password"
          label="Password"
        />
        <TextField
          value={verifyPassword}
          onChange={(event) => setVerifyPassword(event.target.value)}
          variant="standard"
          margin="normal"
          size="small"
          required={!user}
          fullWidth
          type="password"
          label="Confirm password"
        />
        <TextField
          value={selectedStatus}
          onChange={(event) => setSelectedStatus(event.target.value)}
          select
          variant="standard"
          margin="normal"
          size="small"
          required={!user}
          fullWidth
          label="Status"
        >
          <MenuItem value="ACTIVE">ACTIVE</MenuItem>
          <MenuItem value="INACTIVE">INACTIVE</MenuItem>
        </TextField>
        <TextField
          value={selectedRole}
          onChange={(event) => setSelectedRole(event.target.value)}
          select
          variant="standard"
          margin="normal"
          size="small"
          required={!user}
          fullWidth
          label="Role"
        >
          <MenuItem value="ADMIN">ADMIN</MenuItem>
          <MenuItem value="ORGANIZER">ORGANIZER</MenuItem>
          <MenuItem value="CASHIER">CASHIER</MenuItem>
        </TextField>
      </Box>
      <Box sx={{ p: 2, mt: 'auto' }}>
        <Grid columnSpacing={2} container>
          <Grid xs={6} item>
            <Button fullWidth variant="outlined" onClick={onDrawerClose}>
              Cancel
            </Button>
          </Grid>
          <Grid xs={6} item>
            <Button
              disabled={!user ? createDisabled : updateDisabled}
              fullWidth
              variant="contained"
              onClick={!user ? onCreateUser : onUpdateUser}
            >
              {!user ? 'Create' : 'Update'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}

export default UsersDrawer;
