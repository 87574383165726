import React from 'react';
import ReactDOM from 'react-dom/client';
import 'dayjs/locale/ro';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { store, persistor } from './redux/store';
import reportWebVitals from './reportWebVitals';
import App from './App';
import Venues from './pages/Venues';
import Plays from './pages/Plays';
import Events from './pages/Events';
import EventDetails from './pages/EventDetails';
import Categories from './pages/Categories';
import SeatSelector from './pages/SeatSelector';
import OrderStatus from './pages/OrderStatus';
import Login from './pages/Login';
import Orders from './pages/Orders';
import Users from './pages/Users';
import PaymentPoints from './pages/PaymentPoints';
import Organizers from './pages/Organizers';
import OrganizerDetails from './pages/OrganizerDetails';
import Payments from './pages/Payments';
import Artists from './pages/Artists';
import VenueDetails from './pages/VenueDetails';
import Scan from './pages/Scan';
import ResetPassword from './pages/ResetPassword';
import RequireAuth from './components/RequireAuth';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-toastify/dist/ReactToastify.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <RequireAuth>
        <App />
      </RequireAuth>
    ),
    children: [
      {
        path: 'venues',
        element: <Venues />,
      },
      {
        path: 'venues/:venueId',
        element: <VenueDetails />,
      },
      {
        path: 'plays',
        element: <Plays />,
      },
      {
        path: 'events',
        element: <Events />,
      },
      {
        path: 'events/:eventId',
        element: <EventDetails />,
      },
      {
        path: 'orders',
        element: <Orders />,
      },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'paymentPoints',
        element: <PaymentPoints />,
      },
      {
        path: 'organizers',
        element: <Organizers />,
      },
      {
        path: 'organizers/:organizerId',
        element: <OrganizerDetails />,
      },
      {
        path: 'payments',
        element: <Payments />,
      },
      {
        path: 'artists',
        element: <Artists />,
      },
    ],
  },
  { path: '/seats/:eventId', element: <SeatSelector /> },
  { path: '/login', element: <Login /> },
  { path: '/categories/:eventId', element: <Categories /> },
  { path: '/orderStatus', element: <OrderStatus /> },
  { path: '/scan/:code', element: <Scan /> },
  { path: '/reset-password/:code?', element: <ResetPassword /> },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#D53541',
    },
    background: {
      default: '#1E1E1E',
      paper: '#232222',
    },
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <ToastContainer theme="dark" />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ro">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <RouterProvider router={router} />
          </PersistGate>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
