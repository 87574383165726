import React from 'react';
import { useDispatch } from 'react-redux';
import { setOpenCheckout } from '../redux/slices/cartSlice';
import { useTheme } from '@mui/material';
import seatInfoFormatter from '../utils/seatInfoFormatter';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';

function DesktopDrawer({ drawerWidth, seats, totalPrice, onRemove, onRemoveAll }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="right"
    >
      {seats.length ? (
        <List>
          {seats.map(({ id, section, row, seat, basePrice, price, discount, isFree, categoryLabel, categoryColor }) => (
            <ListItem sx={{ borderLeft: `5px solid ${categoryColor}` }} key={id} divider>
              <ListItemText
                sx={{ maxWidth: 200 }}
                primary={`${categoryLabel}`}
                secondary={seatInfoFormatter(section, row, seat)}
              />
              <ListItemSecondaryAction>
                <Grid container justifyItems="center" alignItems="center">
                  {isFree || discount ? (
                    <Stack>
                      <span style={{ textDecoration: 'line-through', fontSize: '0.70rem' }}>
                        {(isFree ? price : basePrice).toFixed(2) + ' Lei'}
                      </span>
                      <span style={{ color: theme.palette.error.main }}>
                        {(isFree ? 0 : price).toFixed(2) + ' Lei'}
                      </span>
                    </Stack>
                  ) : (
                    price.toFixed(2) + ' Lei'
                  )}
                  <IconButton edge="end" aria-label="delete" onClick={() => onRemove(id)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          {!!seats.length && (
            <ListItem>
              <Button fullWidth variant="text" size="small" onClick={onRemoveAll}>
                Șterge
              </Button>
            </ListItem>
          )}
        </List>
      ) : (
        <Box sx={{ height: '100%' }} display="flex" alignItems="center" justifyContent="center">
          <Grid item>
            <Typography variant="h6">Niciun loc selectat</Typography>
          </Grid>
        </Box>
      )}
      <List sx={{ marginTop: 'auto' }}>
        <Divider />
        <ListItem>
          <ListItemText primary={'Total:'}></ListItemText>
          <ListItemSecondaryAction>{`${totalPrice.toFixed(2)} Lei`}</ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <Button
            disabled={!seats.length}
            onClick={() => dispatch(setOpenCheckout(true))}
            fullWidth
            variant="contained"
            size="big"
          >
            Finalizare comandă
          </Button>
        </ListItem>
      </List>
    </Drawer>
  );
}

export default DesktopDrawer;
