import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabPanel from '../components/TabPanel';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import WarningDialog from '../components/WarningDialog';
import TransfersTable from '../components/TransfersTable';
import EventsRevenueTable from '../components/EventsRevenueTable';
import EventsNotificationsTable from '../components/EventsNotificationsTable';
import NotificationsTransfersTable from '../components/NotificationsTransfersTable';
import api from '../api';

function UploadStatementDialog({ open, onClose, refreshEvents }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const onCloseInternal = useCallback(
    (event, reason) => {
      if (reason && reason === 'backdropClick') return;
      setSelectedFile(null);
      onClose();
    },
    [onClose]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length) {
        setSelectedFile(acceptedFiles[0]);
      }
    },
    [setSelectedFile]
  );

  const onUpload = useCallback(() => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('statement', selectedFile);
      setIsUploading(true);
      api
        .post(`/transfers/upload-statement`, formData)
        .then(() => {
          setIsUploading(false);
          refreshEvents();
          onCloseInternal();
          toast.success('Statement processed successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        })
        .catch((err) => {
          setIsUploading(false);
          console.error(err);
          toast.error('An error occured please try again!', { position: toast.POSITION.BOTTOM_RIGHT });
        });
    }
  }, [selectedFile, refreshEvents, onCloseInternal]);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    autoFocus: true,
    onDrop,
    // TODO: handle this better someday
    onError: (err) => console.error(err),
    maxFiles: 1,
    maxSize: 10000000,
    multiple: false,
    accept: {
      'text/csv': ['.csv'],
    },
  });

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const dropAreaStyle = useMemo(() => {
    const border = isDragAccept
      ? { borderColor: theme.palette.success.main }
      : isDragReject
      ? { borderColor: theme.palette.error.main }
      : { borderColor: '#8a8a8a' };

    const common = {
      padding: '8px',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: '2px',
      borderRadius: '3%',
      borderStyle: 'dashed',
    };

    return fullScreen
      ? {
          width: '100%',
          height: '100%',
          ...common,
          ...border,
        }
      : {
          minWidth: 400,
          minHeight: 300,
          ...common,
          ...border,
        };
  }, [theme, isDragAccept, isDragReject, fullScreen]);

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onCloseInternal}>
      <DialogTitle gutterBottom>Upload Bank Statements</DialogTitle>
      <Divider />
      <DialogContent>
        <div {...getRootProps()} style={dropAreaStyle}>
          <input {...getInputProps()} />
          {!selectedFile &&
            (isDragActive ? (
              <Typography variant="body2">Drop statement here...</Typography>
            ) : (
              <Typography variant="body2">Drag 'n' drop statement here, or click to select it</Typography>
            ))}
          {selectedFile && selectedFile.name}
        </div>
        <Box pt={2} pb={2}>
          <Typography variant="body2">Max size: 10MB</Typography>
          <Typography variant="body2">File format: CSV</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCloseInternal}>
          Cancel
        </Button>
        <Button onClick={onUpload} disabled={!selectedFile || isUploading} variant="contained">
          {isUploading && <CircularProgress size={24} sx={{ ml: 1, color: 'white' }} />}
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function Payouts() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openUpload, setOpenUpload] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCash, setSelectedCash] = useState([]);
  const [selectedCard, setSelectedCard] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState([]);
  const [openWarning, setOpenWarning] = useState(false);
  const [events, setEvents] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const notificationEvents = useMemo(() => events.filter(({ is_notification }) => is_notification), [events]);

  const cardEvents = useMemo(() => events.filter(({ is_card }) => is_card), [events]);

  const cashEvents = useMemo(() => events.filter(({ is_cash }) => is_cash), [events]);

  const cardTransfers = useMemo(() => transfers.filter(({ type }) => type === 'CARD'), [transfers]);

  const cashTransfers = useMemo(() => transfers.filter(({ type }) => type === 'CASH'), [transfers]);

  const notifTransfers = useMemo(() => transfers.filter(({ type }) => type === 'NOTIFICATION'), [transfers]);

  const user = useSelector((state) => state.auth.user);

  const toggleOpenWarning = () => setOpenWarning((openWarning) => !openWarning);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getTabIndex = useCallback(
    (tab) => {
      if ((user.role === 'admin' || user.role === 'organizer') && tab === 'card') {
        return 0;
      }
      if ((user.role === 'admin' || user.role === 'organizer') && tab === 'cash') {
        return 1;
      }
      if ((user.role === 'admin' || user.role === 'organizer') && tab === 'notifications') {
        return 2;
      }
      if ((user.role === 'admin' || user.role === 'organizer') && tab === 'cardReports') {
        return 3;
      }
      if ((user.role === 'admin' || user.role === 'organizer') && tab === 'cashReports') {
        return 4;
      }
      if ((user.role === 'admin' || user.role === 'organizer') && tab === 'notificationReports') {
        return 5;
      }

      if (user.role === 'cashier' && tab === 'cash') {
        return 0;
      }
      if (user.role === 'cashier' && tab === 'cashReports') {
        return 1;
      }
    },
    [user]
  );

  const togleUpload = () => setOpenUpload((openUpload) => !openUpload);

  const fetchEvents = useCallback(() => {
    setIsLoading(true);
    api
      .get(`/transfers/events`)
      .then(({ data: { data } }) => {
        setIsLoading(false);
        setEvents(data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        toast.error('An error occured please try again!', { position: toast.POSITION.BOTTOM_RIGHT });
      });
  }, [setIsLoading, setEvents]);

  const fetchTransfers = useCallback(() => {
    setIsLoading(true);
    api
      .get(`/transfers`)
      .then(({ data: { data } }) => {
        const processedData = data.map(({ type, admin_user_id, organizer_user_id, cashier_user_id, ...rest }) => {
          let is_selectable = false;
          if (
            (user.role === 'admin' && !admin_user_id) ||
            (user.role === 'organizer' && type === 'CASH' && !organizer_user_id) ||
            (user.role === 'cashier' && type === 'CASH' && !cashier_user_id)
          ) {
            is_selectable = true;
          }
          return {
            is_selectable,
            type,
            ...rest,
          };
        });
        setIsLoading(false);
        setTransfers(processedData);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        toast.error('An error occured please try again!', { position: toast.POSITION.BOTTOM_RIGHT });
      });
  }, [user, setIsLoading, setTransfers]);

  const onTransfer = useCallback(() => {
    setIsLoading(true);
    const selectedItems = tabIndex === 0 ? selectedCard : tabIndex === 1 ? selectedCash : selectedNotification;
    const selectedFunc = tabIndex === 0 ? setSelectedCard : tabIndex === 1 ? setSelectedCash : setSelectedNotification;
    const type = tabIndex === 0 ? 'card' : tabIndex === 1 ? 'cash' : 'notification';
    api
      .post(`/transfers`, { event_ids: selectedItems, type })
      .then(() => {
        setIsLoading(false);
        selectedFunc([]);
        fetchEvents();
        fetchTransfers();
        toggleOpenWarning();
        toast.success('Payout created successfuly!', { position: toast.POSITION.BOTTOM_RIGHT });
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        toast.error('An error occured please try again!', { position: toast.POSITION.BOTTOM_RIGHT });
      });
  }, [selectedCard, selectedCash, selectedNotification, tabIndex, fetchEvents, fetchTransfers]);

  useEffect(() => {
    const selectedTabId = searchParams.get('selectedTabId');
    if (selectedTabId) {
      setTabIndex(+selectedTabId);
    } else {
      searchParams.set('selectedTabId', 0);
      setSearchParams(searchParams);
    }
  }, [setTabIndex, setSearchParams, searchParams]);

  useEffect(() => {
    fetchEvents();
    fetchTransfers();
  }, [fetchEvents, fetchTransfers]);

  return (
    <div className="payouts">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3">Payments</Typography>
        </Grid>
      </Grid>
      <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabIndex}
          onChange={(_, newValue) => {
            searchParams.set('selectedTabId', newValue);
            setSearchParams(searchParams);
            setTabIndex(newValue);
          }}
        >
          {(user.role === 'organizer' || user.role === 'admin') && <Tab label="Card Revenue" />}
          <Tab label="Cash Revenue" />
          {(user.role === 'organizer' || user.role === 'admin') && <Tab label="Notifications" />}
          {(user.role === 'organizer' || user.role === 'admin') && <Tab label="Card Payouts" />}
          <Tab label="Cash Payouts" />
          {(user.role === 'organizer' || user.role === 'admin') && <Tab label="Notification Payouts" />}
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={getTabIndex('card')}>
        {user.role === 'admin' && (
          <Box display="flex" justifyContent="end" alignItems="center">
            <Button
              disabled={isLoading || !cardEvents.length || !selectedCard.length}
              sx={{ marginRight: 2 }}
              variant="contained"
              onClick={toggleOpenWarning}
            >
              Payout
            </Button>
            <IconButton onClick={handleMenu}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={!!anchorEl}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  togleUpload();
                  handleClose();
                }}
              >
                Upload Statements
              </MenuItem>
            </Menu>
          </Box>
        )}
        <EventsRevenueTable events={cardEvents} selected={selectedCard} setSelected={setSelectedCard} />
      </TabPanel>
      <TabPanel value={tabIndex} index={getTabIndex('cash')}>
        {user.role === 'admin' && (
          <Box display="flex" justifyContent="end" alignItems="center">
            <Button
              disabled={isLoading || !cashEvents.length || !selectedCash.length}
              variant="contained"
              onClick={toggleOpenWarning}
            >
              Payout
            </Button>
          </Box>
        )}
        <EventsRevenueTable events={cashEvents} selected={selectedCash} setSelected={setSelectedCash} />
      </TabPanel>
      <TabPanel value={tabIndex} index={getTabIndex('notifications')}>
        {user.role === 'admin' && (
          <Box display="flex" justifyContent="end" alignItems="center">
            <Button
              disabled={isLoading || !notificationEvents.length || !selectedNotification.length}
              variant="contained"
              onClick={toggleOpenWarning}
            >
              Payout
            </Button>
          </Box>
        )}
        <EventsNotificationsTable
          events={notificationEvents}
          selected={selectedNotification}
          setSelected={setSelectedNotification}
        />
      </TabPanel>
      {user.role !== 'cashier' && (
        <TabPanel value={tabIndex} index={getTabIndex('cardReports')}>
          <TransfersTable transfers={cardTransfers} fetchEvents={fetchEvents} fetchTransfers={fetchTransfers} />
        </TabPanel>
      )}
      <TabPanel value={tabIndex} index={getTabIndex('cashReports')}>
        <TransfersTable transfers={cashTransfers} fetchEvents={fetchEvents} fetchTransfers={fetchTransfers} />
      </TabPanel>
      <TabPanel value={tabIndex} index={getTabIndex('notificationReports')}>
        <NotificationsTransfersTable
          transfers={notifTransfers}
          fetchEvents={fetchEvents}
          fetchTransfers={fetchTransfers}
        />
      </TabPanel>
      <UploadStatementDialog open={openUpload} onClose={togleUpload} refreshEvents={fetchEvents} />
      <WarningDialog
        title={'Warning'}
        content={'This action cannot be undone, make sure selection is corect'}
        loading={isLoading}
        open={openWarning}
        onCancel={toggleOpenWarning}
        onConfirm={onTransfer}
      />
    </div>
  );
}
