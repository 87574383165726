// TODO: refactor this, its also used in SeatSelector.js
const priceFormatter = (price) => price.toFixed(2) + ' Lei';

export default function ChartLegend({ categories }) {
  return (
    <div
      style={{
        zIndex: 500,
        cursor: 'default',
        textAlign: 'center',
        maxHeight: '140px',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginTop: '10px',
        display: 'inline-flex',
        overflowY: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
      }}
    >
      {categories
        .filter(({ available }) => available)
        .map(({ categories_id, price, discount, name, color }) => (
          <div
            key={categories_id}
            style={{
              marginLeft: '4px',
              marginRight: '4px',
              color: '#b3b3b3',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '10px',
              fontSize: '13px',
              fontWeight: '600',
              display: 'inline-flex',
            }}
          >
            <div
              style={{
                marginRight: '6px',
                width: '12px',
                height: '12px',
                backgroundColor: color,
                borderRadius: '49%',
                flexShrink: 0,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'inline-flex',
                position: 'relative',
              }}
            />
            <span>{`${name}: ${priceFormatter(price - price * discount)}`}</span>
          </div>
        ))}
    </div>
  );
}
