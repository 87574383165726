import { useMemo } from 'react';
import { PromotionType } from '../redux/slices/cartSlice';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import DiscountIcon from '@mui/icons-material/Discount';

export default function NPlusOneFreeDialog({ promotionType, open, onConfirm }) {
  const contentText = useMemo(() => {
    if (promotionType === PromotionType.ONE_PLUS_ONE) {
      return (
        <>
          Pentru acest eveniment este activă campania <span style={{ fontWeight: 'bold' }}>1+1 GRATUIT!</span> Adǎugați
          2 bilete în coş şi plǎtiți doar 1!
          <br />
          <br />
          <span style={{ fontWeight: 'bold' }}>Important:</span> oferta este valabilă pentru bilete din aceeaşi
          categorie de preţ!
        </>
      );
    } else if (promotionType === PromotionType.THREE_PLUS_ONE) {
      return (
        <>
          Pentru acest eveniment, este activǎ campania <span style={{ fontWeight: 'bold' }}>3+1 GRATUIT!</span> Adǎugați
          4 bilete în coş şi plǎtiți doar 3!
          <br />
          <br />
          <span style={{ fontWeight: 'bold' }}>Important:</span> campania este valabilǎ doar pentru locuri din aceeaşi
          categorie de preț!
        </>
      );
    }
    return '';
  }, [promotionType]);

  if (!promotionType || promotionType === PromotionType.NONE) return;

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <DiscountIcon sx={{ marginRight: 1 }} />
          Promoție!
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} variant="contained">
          Am înțeles
        </Button>
      </DialogActions>
    </Dialog>
  );
}
