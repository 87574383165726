import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { resetPassword, sendResetCode } from '../redux/slices/authSlice';
import validator from 'validator';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { code } = useParams();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const error = useSelector((state) => state.auth.error);

  useEffect(() => {
    if (error) {
      toast.error('An error occured please try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [error]);

  const formConfig = {
    email: {
      value: '',
      error: false,
      errorText: 'Field is not a valid email',
      isValid: (value) => {
        return validator.isEmail(value);
      },
    },
  };

  const setNewPwConfig = {
    password: {
      value: '',
      error: false,
      errorText: 'Field is not a valid password',
      isValid: (value) => {
        return validator.isStrongPassword(value);
      },
    },
    verifyPassword: {
      value: '',
      error: false,
      errorText: 'Passwords do not match',
      isValid: (value, state) => {
        return state.password.value === value;
      },
    },
  };

  const [formState, setFormState] = useState(formConfig);
  const [formStatePw, setFormStatePw] = useState(setNewPwConfig);

  const onInputChange = (input) => {
    setFormState((currentState) => ({
      ...currentState,
      [input.target.name]: {
        ...currentState[input.target.name],
        value: input.target.type === 'checkbox' ? input.target.checked : input.target.value,
        error: false,
      },
    }));
  };

  const onInputPwChange = (input) => {
    setFormStatePw((currentState) => ({
      ...currentState,
      [input.target.name]: {
        ...currentState[input.target.name],
        value: input.target.type === 'checkbox' ? input.target.checked : input.target.value,
        error: false,
      },
    }));
  };

  const onSetPwFormSubmit = () => {
    const inputs = Object.keys(formStatePw);
    const newState = Object.assign({}, formStatePw);
    let hasError = false;
    for (const input of inputs) {
      if (!newState[input].isValid(newState[input].value, newState)) {
        hasError = true;
        newState[input].error = true;
      }
    }

    setFormStatePw(newState);

    if (!hasError) {
      const payload = {
        code,
        password: newState.password.value,
        verify_password: newState.verifyPassword.value,
      };

      dispatch(resetPassword(payload)).then(({ payload }) => {
        if (payload) {
          toast.success('Password reset succesfully!', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          navigate('/login');
        }
      });
    }
  };

  const onFormSubmit = () => {
    const inputs = Object.keys(formState);
    const newState = Object.assign({}, formState);
    let hasError = false;
    for (const input of inputs) {
      if (!newState[input].isValid(newState[input].value)) {
        hasError = true;
        newState[input].error = true;
      }
    }

    setFormState(newState);

    if (!hasError) {
      const payload = {
        email: newState.email.value,
      };

      dispatch(sendResetCode(payload)).then(({ payload }) => {
        if (payload) {
          toast.success('A code was sent to your email address!', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          navigate('/login');
        }
      });
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <Typography component={Link} to={'/'} sx={{ color: 'white', textDecoration: 'none' }} variant="h6" noWrap>
            POFTIM CULTURA
          </Typography>
        </Toolbar>
      </AppBar>
      <Container sx={{ height: '100vh' }}>
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {!code && (
            <Paper sx={{ p: 2, width: 350 }}>
              <Typography gutterBottom variant="h5">
                Forgot your password?
              </Typography>
              <Typography variant="subtitle2">
                Enter your email and we will send instructions to change password
              </Typography>
              <TextField
                value={formState.email.value}
                onChange={onInputChange}
                error={formState.email.error}
                helperText={formState.email.error ? formState.email.errorText : ''}
                type="text"
                variant="standard"
                margin="normal"
                size="small"
                fullWidth
                name="email"
                label="Email"
              />
              <Button sx={{ mt: 2, mb: 2 }} fullWidth disabled={isLoading} onClick={onFormSubmit} variant="contained">
                Continue
              </Button>
            </Paper>
          )}
          {code && (
            <Paper sx={{ p: 2, width: 350 }}>
              <Typography variant="h5">Set your password</Typography>
              <TextField
                value={formStatePw.password.value}
                onChange={onInputPwChange}
                error={formStatePw.password.error}
                helperText={
                  formStatePw.password.error
                    ? formStatePw.password.errorText
                    : 'Min length 8, 1 upper, 1 lower, 1 number and 1 symbol'
                }
                type="password"
                variant="standard"
                margin="normal"
                size="small"
                fullWidth
                name="password"
                label="Password"
              />
              <TextField
                value={formStatePw.verifyPassword.value}
                onChange={onInputPwChange}
                error={formStatePw.verifyPassword.error}
                helperText={formStatePw.verifyPassword.error ? formStatePw.verifyPassword.errorText : ''}
                type="password"
                variant="standard"
                margin="normal"
                size="small"
                fullWidth
                name="verifyPassword"
                label="Confirm password"
              />
              <Button
                sx={{ mt: 2, mb: 2 }}
                fullWidth
                disabled={isLoading}
                onClick={onSetPwFormSubmit}
                variant="contained"
              >
                Continue
              </Button>
            </Paper>
          )}
        </Box>
      </Container>
    </>
  );
}

export default ResetPassword;
