import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { openFileInNewTab } from '../utils/fileUtils';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Chip from '@mui/material/Chip';
import NoData from './NoData';
import WarningDialog from './WarningDialog';
import api from '../api';

function MoreActions({ transfer }) {
  const user = useSelector((state) => state.auth.user);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDocument = (type) => {
    return () => {
      const url = `/transfers/${transfer.id}/bill`;
      const tabWindow = window.open('', '_blank');
      handleClose();
      api
        .get(url, { responseType: 'blob' })
        .then((res) => {
          const data = res.data;
          openFileInNewTab(tabWindow, data, 'application/pdf');
        })
        .catch((err) => {
          toast.error('An error occured please try again!', { position: toast.POSITION.BOTTOM_RIGHT });
        });
    };
  };

  return (
    <>
      <IconButton size="small" aria-label="more options" onClick={handleMenu}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {user.role !== 'cashier' && <MenuItem onClick={openDocument('bill')}>Bill</MenuItem>}
      </Menu>
    </>
  );
}

function NotificationsTransfersTable({ transfers, fetchEvents, fetchTransfers }) {
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const toggleOpenWarning = () => setOpenWarning((openWarning) => !openWarning);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = transfers.filter((n) => n.is_selectable).map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (transfer, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const onComplete = () => {
    setIsLoading(true);
    api
      .patch(`/transfers`, { transfer_ids: selected })
      .then(() => {
        setIsLoading(false);
        setSelected([]);
        fetchEvents();
        fetchTransfers();
        toggleOpenWarning();
        toast.success('Payout completed successfuly!', { position: toast.POSITION.BOTTOM_RIGHT });
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        toast.error('An error occured please try again!', { position: toast.POSITION.BOTTOM_RIGHT });
      });
  };

  return (
    <>
      <Box display="flex" justifyContent="end" alignItems="center">
        <Button
          disabled={isLoading || !transfers.length || !selected.length}
          variant="contained"
          onClick={toggleOpenWarning}
        >
          Complete
        </Button>
      </Box>
      {transfers.length ? (
        <TableContainer sx={{ marginTop: 2 }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    disabled={transfers.every(({ is_selectable }) => !is_selectable)}
                    indeterminate={
                      selected.length > 0 && selected.length < transfers.filter((n) => n.is_selectable).length
                    }
                    checked={
                      transfers.length > 0 &&
                      selected.length > 0 &&
                      selected.length === transfers.filter((n) => n.is_selectable).length
                    }
                    onChange={handleSelectAllClick}
                    color="primary"
                  />
                </TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Updated</TableCell>
                <TableCell>From</TableCell>
                {user.role !== 'organizer' && <TableCell>Organizer</TableCell>}
                <TableCell>Company</TableCell>
                <TableCell>Bill ID</TableCell>
                <TableCell align="center">Comission Status</TableCell>
                {user.role !== 'cashier' && <TableCell align="right">Comission</TableCell>}
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transfers.map(
                ({
                  id,
                  sender,
                  created_at,
                  updated_at,
                  organizer,
                  company,
                  is_comission_paid,
                  comission,
                  type,
                  is_selectable,
                  bill_series,
                  bill_number,
                }) => (
                  <TableRow
                    key={id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:nth-of-type(odd)': (theme) => ({
                        backgroundColor: theme.palette.action.hover,
                      }),
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        disabled={!is_selectable}
                        checked={isSelected(id)}
                        onClick={(event) => handleClick(event, id)}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>{id}</TableCell>
                    <TableCell>{dayjs(created_at).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{dayjs(updated_at).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{sender}</TableCell>
                    {user.role !== 'organizer' && <TableCell>{organizer}</TableCell>}
                    <TableCell>{company}</TableCell>
                    <TableCell>{bill_series && bill_number ? `${bill_series}${bill_number}` : '-'}</TableCell>
                    <TableCell align="center">
                      {is_comission_paid ? (
                        <Chip
                          sx={{ mt: 1, borderRadius: 1 }}
                          size="small"
                          label="PAID"
                          variant="outlined"
                          color="success"
                        />
                      ) : (
                        <Chip
                          sx={{ mt: 1, borderRadius: 1 }}
                          size="small"
                          label="UNPAID"
                          variant="outlined"
                          color="error"
                        />
                      )}
                    </TableCell>
                    <TableCell align="right">{comission} Lei</TableCell>
                    <TableCell align="right">
                      <MoreActions transfer={{ id, type }} />
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoData />
      )}
      <WarningDialog
        title={'Warning'}
        content={'This action cannot be undone, make sure selection is corect'}
        loading={isLoading}
        open={openWarning}
        onCancel={toggleOpenWarning}
        onConfirm={onComplete}
      />
    </>
  );
}

export default NotificationsTransfersTable;
