import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import WarningIcon from '@mui/icons-material/Warning';
import CircularProgress from '@mui/material/CircularProgress';

export default function WarningDialog({ title, content, loading, open, onCancel, onConfirm }) {
  return (
    <Dialog open={open}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <WarningIcon sx={{ marginRight: 1 }} />
          {title}
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={loading} onClick={onConfirm} variant="contained">
          Continue
          {loading && <CircularProgress size={24} sx={{ ml: 1, color: 'white' }} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
