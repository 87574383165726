import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchOrganizer, fetchOrganizerUsers, fetchOrganizerVenues } from '../redux/slices/organizersSlice';
import { fetchUsers } from '../redux/slices/usersSlice';
import { fetchVenues } from '../redux/slices/venuesSlice';
import { useTheme, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabPanel from '../components/TabPanel';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import OrganizerDrawer from '../components/OrganizerDrawer';
import api from '../api';

function LinkListUser({ organizerId, id, first_name, last_name, email, isLinked }) {
  const [isChecked, setIsChecked] = useState(false);
  const [checkedDisabled, setCheckedDisabled] = useState(false);

  useEffect(() => {
    setIsChecked(isLinked);
  }, [isLinked]);

  const onChange = (event) => {
    const checked = event.target.checked;
    setCheckedDisabled(true);
    api
      .patch(`/organizers/${organizerId}/users`, { user_id: id, action: checked ? 'link' : 'unlink' })
      .then(() => {
        setIsChecked(checked);
        setCheckedDisabled(false);
      })
      .catch(() => {
        toast.error('An error occured, please try again!', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setCheckedDisabled(false);
      });
  };

  return (
    <ListItem key={id} divider>
      <ListItemText primary={`${first_name} ${last_name}`} secondary={email} />
      <ListItemSecondaryAction>
        <Checkbox disabled={checkedDisabled} checked={isChecked} onChange={onChange} />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function LinkUserDialog({ open, onClose, organizer }) {
  const onCloseInternal = () => {
    onClose();
  };

  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const usersError = useSelector((state) => state.users.error);

  const users = useSelector((state) => {
    return state.users.data.map((user) => {
      const isLinked = !!state.organizers.organizerUsers.find((linkedUser) => linkedUser.users_id === user.id);
      return { ...user, isLinked };
    });
  });

  useEffect(() => {
    if (usersError) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [usersError]);

  useEffect(() => {
    if (open) {
      dispatch(fetchUsers({ role: 'ORGANIZER' }));
      // TODO: what happens here if it fails?!
      dispatch(fetchOrganizerUsers({ organizerId: organizer.id }));
    }
  }, [dispatch, open, organizer]);

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <DialogTitle gutterBottom>
        {organizer.name} Users
        <IconButton
          aria-label="close"
          onClick={onCloseInternal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: 0 }}>
        {users.length ? (
          <List sx={{ minHeight: 500, minWidth: 400 }}>
            {users.map((user) => (
              <LinkListUser key={user.id} organizerId={organizer.id} {...user} />
            ))}
          </List>
        ) : (
          <Box sx={{ minHeight: 500, minWidth: 400 }} display="flex" alignItems="center" justifyContent="center">
            <Typography sx={{ color: '#888888' }} variant="h6">
              No data to display
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

function LinkListVenue({ organizerId, id, name, city, isLinked }) {
  const [isChecked, setIsChecked] = useState(false);
  const [checkedDisabled, setCheckedDisabled] = useState(false);

  useEffect(() => {
    setIsChecked(isLinked);
  }, [isLinked]);

  const onChange = (event) => {
    const checked = event.target.checked;
    setCheckedDisabled(true);
    api
      .patch(`/organizers/${organizerId}/venues`, { venue_id: id, action: checked ? 'link' : 'unlink' })
      .then(() => {
        setIsChecked(checked);
        setCheckedDisabled(false);
      })
      .catch(() => {
        toast.error('An error occured, please try again!', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setCheckedDisabled(false);
      });
  };

  return (
    <ListItem key={id} divider>
      <ListItemText primary={name} secondary={city} />
      <ListItemSecondaryAction>
        <Checkbox disabled={checkedDisabled} checked={isChecked} onChange={onChange} />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function LinkVenueDialog({ open, onClose, organizer }) {
  const onCloseInternal = () => {
    onClose();
  };

  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const venuesError = useSelector((state) => state.venues.error);

  const venues = useSelector((state) => {
    return state.venues.data.map((venue) => {
      const isLinked = !!state.organizers.organizerVenues.find((linkedVenue) => linkedVenue.venues_id === venue.id);
      return { ...venue, isLinked };
    });
  });

  useEffect(() => {
    if (venuesError) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [venuesError]);

  useEffect(() => {
    if (open) {
      dispatch(fetchVenues());
      // TODO: what happens here if it fails?!
      dispatch(fetchOrganizerVenues({ organizerId: organizer.id }));
    }
  }, [dispatch, open, organizer]);

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <DialogTitle gutterBottom>
        {organizer.name} Venues
        <IconButton
          aria-label="close"
          onClick={onCloseInternal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: 0 }}>
        {venues.length ? (
          <List sx={{ minHeight: 500, minWidth: 400 }}>
            {venues.map((venue) => (
              <LinkListVenue key={venue.id} organizerId={organizer.id} {...venue} />
            ))}
          </List>
        ) : (
          <Box sx={{ minHeight: 500, minWidth: 400 }} display="flex" alignItems="center" justifyContent="center">
            <Typography sx={{ color: '#888888' }} variant="h6">
              No data to display
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default function OrganizerDetails() {
  const dispatch = useDispatch();
  const { organizerId } = useParams();
  const [editOrganizer, setEditOrganizer] = useState(null);
  const [openUsers, setOpenUsers] = useState(false);
  const [openVenues, setOpenVenues] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const organizer = useSelector((state) => state.organizers.selectedOrganizer);
  //const isLoading = useSelector((state) => state.organizers.isLoading);
  const error = useSelector((state) => state.organizers.error);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openEdit = useCallback(() => {
    setEditOrganizer(organizer);
    handleClose();
  }, [organizer]);

  const closeEdit = () => {
    setEditOrganizer(null);
  };

  const toggleUsers = () => setOpenUsers((openUsers) => !openUsers);
  const toggleVenues = () => setOpenVenues((openVenues) => !openVenues);

  useEffect(() => {
    if (organizerId) {
      dispatch(fetchOrganizer({ organizerId }));
    }
  }, [dispatch, organizerId]);

  useEffect(() => {
    if (error) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [error]);

  const statusChip = useMemo(() => {
    const { status } = organizer;

    if (!status) return null;

    const chipColor = status === 'INACTIVE' ? 'error' : 'success';

    return (
      <Chip
        onClick={openEdit}
        sx={{ ml: 2, borderRadius: 1 }}
        size="small"
        label={status}
        variant="outlined"
        color={chipColor}
      />
    );
  }, [organizer, openEdit]);

  return (
    <div className="organizer-details">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box display="flex" alignItems="center">
            <Typography variant="h3">{organizer.name}</Typography>
            {statusChip}
          </Box>
        </Grid>
        <Grid item>
          <IconButton onClick={handleMenu}>
            <MoreHorizIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={!!anchorEl}
            onClose={handleClose}
          >
            <MenuItem onClick={openEdit}>Edit</MenuItem>
            <MenuItem
              onClick={() => {
                toggleUsers();
                handleClose();
              }}
            >
              Users
            </MenuItem>
            <MenuItem
              onClick={() => {
                toggleVenues();
                handleClose();
              }}
            >
              Venues
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={(_, newValue) => setTabIndex(newValue)}>
          <Tab label="Details" />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography gutterBottom variant="h5">
            Pricings
          </Typography>
        </Grid>
        <TableContainer sx={{ marginTop: 2, marginBottom: 2 }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Platform Comission</TableCell>
                <TableCell>Seat Price</TableCell>
                <TableCell>Cashier Commision/Seat</TableCell>
                <TableCell>Sms Price</TableCell>
                <TableCell>Email Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { borderBottom: 0 },
                  '&:nth-of-type(odd)': (theme) => ({
                    backgroundColor: theme.palette.action.hover,
                  }),
                }}
              >
                <TableCell>{organizer.platform_comission * 100 + ' %'}</TableCell>
                <TableCell>{organizer.seat_price + ' Lei'}</TableCell>
                <TableCell>{!!organizer.comission_cash_per_seat ? 'Yes' : 'No'}</TableCell>
                <TableCell>{organizer.notification_sms_price + ' Lei'}</TableCell>
                <TableCell>{organizer.notification_email_price + ' Lei'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography gutterBottom variant="h5">
            Companies
          </Typography>
        </Grid>
        <TableContainer sx={{ marginTop: 2 }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Tax Code</TableCell>
                <TableCell>Reg. Code</TableCell>
                <TableCell align="center">VAT Payer</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>City</TableCell>
                <TableCell>County</TableCell>
                <TableCell>Representative</TableCell>
                <TableCell align="center">Fiscal Serial Prefix</TableCell>
                <TableCell align="center">Contract ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizer.companies.map(
                ({
                  id,
                  company_name,
                  tax_code,
                  reg_code,
                  is_vat_payer,
                  address,
                  city,
                  county,
                  representative_full_name,
                  fiscal_serial_prefix,
                  contract_id,
                }) => (
                  <TableRow
                    key={id}
                    sx={{
                      '&:last-child td, &:last-child th': { borderBottom: 0 },
                      '&:nth-of-type(odd)': (theme) => ({
                        backgroundColor: theme.palette.action.hover,
                      }),
                    }}
                  >
                    <TableCell>{company_name}</TableCell>
                    <TableCell>{tax_code}</TableCell>
                    <TableCell>{reg_code || '-'}</TableCell>
                    <TableCell align="center">{!!is_vat_payer ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{address}</TableCell>
                    <TableCell>{city}</TableCell>
                    <TableCell>{county}</TableCell>
                    <TableCell>{representative_full_name}</TableCell>
                    <TableCell align="center">{fiscal_serial_prefix}</TableCell>
                    <TableCell align="center">{contract_id}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <LinkVenueDialog open={openVenues} organizer={organizer} onClose={toggleVenues} />
      <LinkUserDialog open={openUsers} organizer={organizer} onClose={toggleUsers} />
      <OrganizerDrawer open={!!editOrganizer} organizer={editOrganizer} onClose={closeEdit} />
    </div>
  );
}
