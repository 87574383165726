import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchChartTemplates, createChart, updateChart } from '../redux/slices/chartsSlice';
import validator from 'validator';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ColorPicker from './ColorPicker';

const defaultColor = '#2FBB2F';

const defaultCategories = [
  { id: 0, name: 'Categoria 1', color: '#F81B1B' },
  { id: 1, name: 'Categoria 2', color: '#58B44F' },
  { id: 2, name: 'Categoria 3', color: '#3190ED' },
  { id: 3, name: 'Categoria 4', color: '#FF8800' },
  { id: 4, name: 'Categoria 5', color: '#8D1CEC' },
  { id: 5, name: 'Categoria 6', color: '#FF00CE' },
  { id: 6, name: 'Categoria 7', color: '#F6D409' },
  { id: 7, name: 'Categoria 8', color: '#00bf63' },
  { id: 8, name: 'Categoria 9', color: '#4c0e26' },
  { id: 9, name: 'Categoria 10', color: '#7f2f16' },
  { id: 10, name: 'Categoria 11', color: '#c5f208' },
  { id: 11, name: 'Categoria 12', color: '#1700f4' },
  { id: 12, name: 'Acces General', color: '#F81B1B' },
];

const empty = (value) => validator.isEmpty(value, { ignore_whitespace: true });
const isLatLng = (value) => validator.isLatLong(value);

function ChartsDrawer({ open, onClose, venue, chart }) {
  const dispatch = useDispatch();
  const [selectedChart, setSelectedChart] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [coordinate, setCoordinate] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  const [selectedColor, setSelectedColor] = useState(defaultColor);
  const [categoryName, setCategoryName] = useState('');
  const [categories, setCategories] = useState([]);

  const chartData = useSelector((state) => state.charts.chartTemplates);
  const chartDataError = useSelector((state) => state.charts.error);
  const chartDataLoading = useSelector((state) => state.charts.isLoading);

  const isLoading = useSelector((state) => state.charts.isLoading);
  const isArchivedChart = useMemo(() => chart && chart.status === 'ARCHIVED', [chart]);

  useEffect(() => {
    if (open && !chart) {
      setCategories(defaultCategories.map((cat) => ({ ...cat, canRemove: true })));
    }
  }, [open, chart]);

  useEffect(() => {
    dispatch(fetchChartTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (chartDataError) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [chartDataError]);

  useEffect(() => {
    if (chart) {
      const { name, address, latitude, longitude, status, categories } = chart;
      setSelectedStatus(status);
      setName(name);
      setAddress(address || '');
      setCoordinate(latitude && longitude ? `${latitude}, ${longitude}` : '');
      setCategories(categories.map((cat) => ({ ...cat, canRemove: false })));
    }
  }, [chart]);

  const onDrawerClose = () => {
    onClose();
    setSelectedChart('');
    setName('');
    setAddress('');
    setCoordinate('');
    setCategories([]);
    setSelectedColor(defaultColor);
    setCategoryName('');
    setSelectedStatus('');
  };

  const addCategory = () => {
    const nextId = categories.length ? categories[categories.length - 1].id + 1 : 0;
    setCategories((current) => [...current, { id: nextId, name: categoryName, color: selectedColor, canRemove: true }]);
    setCategoryName('');
  };

  const removeCategory = (idToRemove) => {
    const idx = categories.findIndex(({ id }) => idToRemove === id);
    const newState = [...categories];
    newState.splice(idx, 1);
    setCategories(newState);
  };

  const onCreate = () => {
    const payload = {
      venue_id: venue.id,
      seatsio_chart_id: selectedChart,
      address,
      coordinate,
      name,
      categories,
    };
    dispatch(createChart(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('Chart created successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        onDrawerClose();
      }
    });
  };

  const onUpdate = () => {
    const payload = {
      venueId: venue.id,
      chartId: chart.id,
      name,
      status: selectedStatus,
      address,
      coordinate,
      categories: categories.filter((c) => c.canRemove === true),
    };
    dispatch(updateChart(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('Chart updated successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        onDrawerClose();
      }
    });
  };

  const createDisabled = () =>
    isLoading ||
    empty(name) ||
    (empty(address) && isLatLng(coordinate)) ||
    (!empty(address) && !isLatLng(coordinate)) ||
    (!empty(coordinate) && !isLatLng(coordinate)) ||
    !selectedChart;

  const updateDisabled = () =>
    isLoading ||
    empty(name) ||
    (empty(address) && isLatLng(coordinate)) ||
    (!empty(address) && !isLatLng(coordinate)) ||
    (!empty(coordinate) && !isLatLng(coordinate));

  return (
    <Drawer anchor="right" open={open}>
      <Toolbar />
      <Toolbar>
        <Typography variant="button">{!chart ? 'Create chart' : `Edit chart`}</Typography>
      </Toolbar>
      <Divider />
      <Box sx={{ width: 400, pl: 2, pr: 2 }}>
        <Box sx={{ pb: 2 }} component="form">
          {!chart && (
            <FormControl required variant="standard" margin="normal" size="small" fullWidth disabled={chartDataLoading}>
              <InputLabel id="seating-chart-label">Template</InputLabel>
              <Select
                value={selectedChart}
                onChange={(event) => setSelectedChart(event.target.value)}
                labelId="seating-chart-label"
                id="seating-chart"
              >
                {chartData.map(({ key, name }) => (
                  <MenuItem key={key} value={key}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {!isArchivedChart && (
            <TextField
              value={name}
              onChange={(event) => setName(event.target.value)}
              required
              variant="standard"
              margin="normal"
              size="small"
              fullWidth
              id="name"
              label="Name"
            />
          )}
          {!isArchivedChart && (
            <TextField
              value={address}
              onChange={(event) => setAddress(event.target.value)}
              variant="standard"
              margin="normal"
              size="small"
              fullWidth
              id="address"
              label="Address"
            />
          )}
          {!isArchivedChart && (
            <TextField
              value={coordinate}
              onChange={(event) => setCoordinate(event.target.value)}
              variant="standard"
              margin="normal"
              size="small"
              fullWidth
              id="coordinate"
              label="Coordinates"
            />
          )}
          {chart && (
            <FormControl variant="standard" margin="normal" size="small" fullWidth>
              <InputLabel id="status">Status</InputLabel>
              <Select
                value={selectedStatus}
                onChange={(event) => setSelectedStatus(event.target.value)}
                labelId="status"
                id="status"
              >
                <MenuItem value={'ACTIVE'}>Active</MenuItem>
                <MenuItem value={'ARCHIVED'}>Archived</MenuItem>
              </Select>
            </FormControl>
          )}
        </Box>
        {!isArchivedChart && (
          <>
            <Box component="form">
              <Typography variant="button">Categories</Typography>
              <Grid sx={{ pt: 3, pb: 2 }} spacing={2} container alignItems="center">
                <Grid item>
                  <ColorPicker onChange={(hex) => setSelectedColor(hex)} />
                </Grid>
                <Grid xs={true} item>
                  <Input
                    value={categoryName}
                    onChange={(event) => setCategoryName(event.target.value)}
                    fullWidth
                    id="category-name"
                    placeholder="Name"
                  />
                </Grid>
              </Grid>
              <Button disabled={empty(categoryName)} onClick={addCategory} fullWidth variant="text" size="small">
                Add
              </Button>
            </Box>
            <List>
              {categories.map(({ id, name, color, canRemove }) => (
                <ListItem sx={{ mb: 1, borderLeft: `5px solid ${color}` }} key={id} divider>
                  <ListItemText primary={`${name}`} />
                  {canRemove && (
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => removeCategory(id)} edge="end" aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Box>
      <Box sx={{ p: 2, mt: 'auto' }}>
        <Grid columnSpacing={2} container>
          <Grid xs={6} item>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                onDrawerClose();
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid xs={6} item>
            <Button
              disabled={!chart ? createDisabled() : updateDisabled()}
              fullWidth
              variant="contained"
              onClick={!chart ? onCreate : onUpdate}
            >
              {!chart ? 'Create' : 'Update'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}

export default ChartsDrawer;
