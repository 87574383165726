import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const fetchUsers = createAsyncThunk('users/list', (args = {}) => {
  const { role } = args;
  return api.get(`/users?role=${role ? encodeURIComponent(role) : ''}`).then((response) => response.data);
});

export const createUser = createAsyncThunk('users/create', (args, { dispatch }) => {
  const { email, first_name, last_name, role, status, password, verify_password } = args;
  return api
    .post('/users', { email, first_name, last_name, role, status, password, verify_password })
    .then(() => dispatch(fetchUsers()));
});

export const updateUser = createAsyncThunk('users/update', (args, { dispatch }) => {
  const { user_id, email, first_name, last_name, role, status, password, verify_password } = args;
  return api
    .put(`/users/${user_id}`, { email, first_name, last_name, role, status, password, verify_password })
    .then(() => dispatch(fetchUsers()));
});

const initialState = {
  data: [],
  error: null,
  isLoading: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.data = [];
    });

    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
    });

    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(createUser.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(createUser.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(createUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateUser.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default usersSlice.reducer;
