import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setOpenCheckout } from '../redux/slices/cartSlice';
import { useTheme } from '@mui/material';
import seatInfoFormatter from '../utils/seatInfoFormatter';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItem from '@mui/material/ListItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

function MobileDrawer({ seats, totalPrice, onRemove, onRemoveAll }) {
  const [open, setOpen] = useState(false);
  const disaptch = useDispatch();
  const theme = useTheme();
  return (
    <Drawer variant="permanent" anchor="bottom">
      <List sx={{ flexGrow: 1 }}>
        <ListItem>
          <ListItemText primary={'Total:'} />
          <ListItemSecondaryAction>{`${totalPrice.toFixed(2)} Lei`}</ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <Button onClick={() => setOpen(true)} disabled={!seats.length} fullWidth variant="contained">
            {`${seats.length} ${seats.length === 1 ? 'loc selectat' : 'locuri selectate'}`}
          </Button>
        </ListItem>
      </List>
      <Dialog fullScreen={true} open={open}>
        <DialogTitle gutterBottom>
          Locuri selectate
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {seats.length ? (
            <List disablePadding>
              {seats.map(
                ({ id, section, row, seat, basePrice, discount, price, isFree, categoryLabel, categoryColor }) => (
                  <ListItem sx={{ borderLeft: `5px solid ${categoryColor}` }} key={id} divider>
                    <ListItemText
                      sx={{ maxWidth: 200 }}
                      primary={`${categoryLabel}`}
                      secondary={seatInfoFormatter(section, row, seat)}
                    />
                    <ListItemSecondaryAction>
                      <Grid container justifyItems="center" alignItems="center">
                        {isFree || discount ? (
                          <Stack>
                            <span style={{ textDecoration: 'line-through', fontSize: '0.70rem' }}>
                              {(isFree ? price : basePrice).toFixed(2) + ' Lei'}
                            </span>
                            <span style={{ color: theme.palette.error.main }}>
                              {(isFree ? 0 : price).toFixed(2) + ' Lei'}
                            </span>
                          </Stack>
                        ) : (
                          price.toFixed(2) + ' Lei'
                        )}
                        <IconButton edge="end" aria-label="delete" onClick={() => onRemove(id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              )}
              {!!seats.length && (
                <ListItem>
                  <Button fullWidth variant="text" size="small" onClick={onRemoveAll}>
                    Șterge
                  </Button>
                </ListItem>
              )}
            </List>
          ) : (
            <Box sx={{ height: '100%' }} display="flex" alignItems="center" justifyContent="center">
              <Typography variant="h6">Niciun loc selectat</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!seats.length}
            onClick={() => {
              disaptch(setOpenCheckout(true));
              setOpen(false);
            }}
            variant="contained"
          >
            Finalizare comandă
          </Button>
        </DialogActions>
      </Dialog>
    </Drawer>
  );
}

export default MobileDrawer;
