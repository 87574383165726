import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const fetchCities = createAsyncThunk('cities/list', () => {
  return api.get('/cities').then((response) => response.data);
});

const initialState = {
  data: [],
  error: null,
  isLoading: false,
};

const citiesSlice = createSlice({
  name: 'cities',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCities.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(fetchCities.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
    });

    builder.addCase(fetchCities.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default citiesSlice.reducer;
