import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const fetchArtists = createAsyncThunk('artists/list', () => {
  return api.get('/artists').then((response) => response.data);
});

export const createArtist = createAsyncThunk('artists/create', (args, { dispatch }) => {
  const { first_name, last_name, description, profile, index_for_search } = args;
  const formData = new FormData();
  formData.append('first_name', first_name);
  formData.append('last_name', last_name);
  formData.append('description', description);
  formData.append('profile', profile);
  formData.append('index_for_search', index_for_search);
  return api.post('/artists', formData).then(() => dispatch(fetchArtists()));
});

export const updateArtist = createAsyncThunk('artists/update', (args, { dispatch }) => {
  const { artistId, first_name, last_name, description, profile, index_for_search } = args;
  const formData = new FormData();
  formData.append('first_name', first_name);
  formData.append('last_name', last_name);
  formData.append('description', description);
  formData.append('profile', profile);
  formData.append('index_for_search', index_for_search);
  return api.patch(`/artists/${artistId}`, formData).then(() => dispatch(fetchArtists()));
});

const initialState = {
  data: [],
  error: null,
  isLoading: false,
};

const artistsSlice = createSlice({
  name: 'artists',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchArtists.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(fetchArtists.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
    });

    builder.addCase(fetchArtists.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(createArtist.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(createArtist.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(createArtist.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateArtist.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(updateArtist.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateArtist.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default artistsSlice.reducer;
