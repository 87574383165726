import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchVenue } from '../redux/slices/venuesSlice';
import { fetchCharts } from '../redux/slices/chartsSlice';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabPanel from '../components/TabPanel';
import LocationIcon from '@mui/icons-material/LocationOn';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import ChartCard from '../components/ChartCard';
import ChartsDrawer from '../components/ChartsDrawer';
import VenuesDrawer from '../components/VenuesDrawer';
import NoData from '../components/NoData';

export default function VenueDetails() {
  const dispatch = useDispatch();
  const { venueId } = useParams();
  const [editVenue, setEditVenue] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const [openCreate, setOpenCreate] = useState(false);
  const [editChart, setEditChart] = useState(null);

  const venue = useSelector((state) => state.venues.selectedVenue);
  const isLoading = useSelector((state) => state.venues.isLoading);
  const error = useSelector((state) => state.venues.error);

  const charts = useSelector((state) => state.charts.data);

  const user = useSelector((state) => state.auth.user);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleOpenCreateChart = () => {
    setOpenCreate(true);
  };

  const toggleOpenUpdateChart = (chart) => {
    setEditChart(chart);
  };

  const toggleOpenUpdateVenue = useCallback(() => {
    setEditVenue(venue);
  }, [venue]);

  const onChartDrawerClose = () => {
    setOpenCreate(false);
    setEditChart(null);
  };

  const onVenueDrawerClose = () => {
    setEditVenue(null);
  };

  useEffect(() => {
    if (venueId) {
      dispatch(fetchVenue({ venueId }));
      dispatch(fetchCharts({ venueId }));
    }
  }, [dispatch, venueId]);

  useEffect(() => {
    if (error) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [error]);

  const statusChip = useMemo(() => {
    const { status } = venue;

    if (!status) return null;

    const chipColor = status === 'INACTIVE' ? 'error' : 'success';

    return (
      <Chip
        {...(user.role === 'admin' ? { onClick: toggleOpenUpdateVenue } : {})}
        sx={{ ml: 2, borderRadius: 1 }}
        size="small"
        label={status}
        variant="outlined"
        color={chipColor}
      />
    );
  }, [user.role, venue, toggleOpenUpdateVenue]);

  const location = useMemo(() => `${venue.address} - ${venue.city} (${venue.latitude}, ${venue.longitude})`, [venue]);

  return (
    <div className="venues">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box display="flex" alignItems="center">
            <Typography variant="h3">{venue.name}</Typography>
            {user.role === 'admin' && statusChip}
          </Box>
          <Box sx={{ mt: 2 }} display="flex" alignItems="center">
            <LocationIcon sx={{ mr: 1 }} fontSize="medium" />
            <Typography variant="h6">{location}</Typography>
          </Box>
        </Grid>
        {user.role === 'admin' && (
          <Grid item>
            <IconButton onClick={handleMenu}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={!!anchorEl}
              onClose={handleClose}
            >
              <MenuItem onClick={toggleOpenUpdateVenue}>Edit</MenuItem>
            </Menu>
          </Grid>
        )}
      </Grid>
      <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={(_, newValue) => setTabIndex(newValue)}>
          <Tab label="Charts" />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        {user.role === 'admin' && (
          <Grid item container justifyContent="end" alignItems="center">
            <Grid item>
              <Fab onClick={toggleOpenCreateChart} size="small" color="primary" disabled={isLoading}>
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        )}
        {charts.length ? (
          <Grid container spacing={4}>
            {charts.map((chart) => (
              <Grid item key={chart.id}>
                <ChartCard chart={chart} {...(user.role === 'admin' ? { onEdit: toggleOpenUpdateChart } : {})} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <NoData />
        )}
      </TabPanel>
      {user.role === 'admin' && (
        <ChartsDrawer open={openCreate || !!editChart} venue={venue} chart={editChart} onClose={onChartDrawerClose} />
      )}
      {user.role === 'admin' && <VenuesDrawer open={!!editVenue} venue={editVenue} onClose={onVenueDrawerClose} />}
    </div>
  );
}
