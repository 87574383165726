import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const fetchVenues = createAsyncThunk('venues/listAll', (args = {}) => {
  const { status, city } = args;
  return api
    .get(`/venues?status=${status ? encodeURIComponent(status) : ''}&city=${city ? encodeURIComponent(city) : ''}`)
    .then((response) => response.data);
});

export const fetchVenue = createAsyncThunk('venues/listOne', (args = {}) => {
  const { venueId } = args;
  return api.get(`/venues/${venueId}`).then((response) => response.data);
});

export const createVenue = createAsyncThunk('venues/create', (args, { dispatch }) => {
  const { name, city, description, address, coordinate, portrait, cover, web_url, index_for_search } = args;
  const formData = new FormData();
  formData.append('name', name);
  formData.append('city', city);
  formData.append('description', description);
  formData.append('address', address);
  formData.append('coordinate', coordinate);
  formData.append('portrait', portrait);
  formData.append('cover', cover);
  formData.append('web_url', web_url);
  formData.append('index_for_search', index_for_search);
  return api.post('/venues', formData).then(() => dispatch(fetchVenues()));
});

export const updateVenue = createAsyncThunk('venues/update', (args, { dispatch }) => {
  const { venueId, name, city, description, address, coordinate, status, portrait, cover, web_url, index_for_search } =
    args;
  const formData = new FormData();
  formData.append('name', name);
  formData.append('city', city);
  formData.append('description', description);
  formData.append('address', address);
  formData.append('status', status);
  formData.append('coordinate', coordinate);
  formData.append('portrait', portrait);
  formData.append('cover', cover);
  formData.append('web_url', web_url);
  formData.append('index_for_search', index_for_search);
  return api.put(`/venues/${venueId}`, formData).then(() => dispatch(fetchVenue({ venueId })));
});

const initialState = {
  data: [],
  selectedVenue: {
    name: '',
    city: '',
    description: '',
    address: '',
    latitude: 0,
    longitude: 0,
    status: 'INACTIVE',
    web_url: '',
    index_for_search: true,
  },
  error: null,
  isLoading: false,
};

const venuesSlice = createSlice({
  name: 'venues',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVenues.pending, (state, action) => {
      state.isLoading = true;
      state.data = [];
      state.error = null;
    });

    builder.addCase(fetchVenues.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
    });

    builder.addCase(fetchVenues.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchVenue.pending, (state, action) => {
      state.isLoading = true;
      state.selectedVenue = initialState.selectedVenue;
      state.error = null;
    });

    builder.addCase(fetchVenue.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedVenue = action.payload.data[0];
    });

    builder.addCase(fetchVenue.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(createVenue.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(createVenue.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(createVenue.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateVenue.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(updateVenue.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateVenue.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default venuesSlice.reducer;
