import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import ValidIcon from '@mui/icons-material/CheckCircle';
import NotValidIcon from '@mui/icons-material/Cancel';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import NoData from './NoData';

export default function EventsRevenueTable({ events, selected, setSelected }) {
  const user = useSelector((state) => state.auth.user);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = events.filter((n) => n.is_valid && !n.transfers.length).map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleEventClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  return events.length ? (
    <TableContainer sx={{ marginTop: 2 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {user.role === 'admin' && (
              <TableCell padding="checkbox">
                <Checkbox
                  disabled={events.every(({ is_valid, transfers }) => !is_valid || !!transfers.length)}
                  indeterminate={
                    selected.length > 0 &&
                    selected.length < events.filter((n) => n.is_valid && !n.transfers.length).length
                  }
                  checked={
                    events.length > 0 &&
                    selected.length > 0 &&
                    selected.length === events.filter((n) => n.is_valid && !n.transfers.length).length
                  }
                  onChange={handleSelectAllClick}
                  color="primary"
                />
              </TableCell>
            )}
            <TableCell>ID</TableCell>
            {user.role !== 'organizer' && <TableCell>Organizer</TableCell>}
            <TableCell>Company</TableCell>
            <TableCell>Event</TableCell>
            <TableCell align="center">Payout ID</TableCell>
            {user.role === 'admin' && <TableCell align="center">Validated</TableCell>}
            <TableCell align="center">Total Status</TableCell>
            {user.role !== 'cashier' && <TableCell align="center">Comission Status</TableCell>}
            <TableCell align="right">{user.role === 'cashier' ? 'Total' : 'Subtotal'}</TableCell>
            {user.role !== 'cashier' && <TableCell align="right">Total</TableCell>}
            {user.role !== 'cashier' && <TableCell align="right">Comission</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map(
            ({ id, date, organizer, company, name, city, subtotal, total, comission, transfers, is_valid }) => {
              const transfer = transfers.length && transfers[0];
              return (
                <TableRow
                  key={id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:nth-of-type(odd)': (theme) => ({
                      backgroundColor: theme.palette.action.hover,
                    }),
                  }}
                >
                  {user.role === 'admin' && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        disabled={!is_valid || !!transfers.length}
                        checked={isSelected(id)}
                        onClick={(event) => handleEventClick(event, id)}
                        color="primary"
                      />
                    </TableCell>
                  )}
                  <TableCell>{id}</TableCell>
                  {user.role !== 'organizer' && <TableCell>{organizer}</TableCell>}
                  <TableCell>{company}</TableCell>
                  <TableCell>{`${dayjs(date).format('DD/MM/YYYY HH:mm')} - ${name} - ${city}`}</TableCell>
                  <TableCell align="center">{transfer ? transfer.id : '-'}</TableCell>
                  {user.role === 'admin' && (
                    <TableCell align="center">
                      {is_valid ? <ValidIcon color="success" /> : <NotValidIcon color="error" />}
                    </TableCell>
                  )}
                  <TableCell align="center">
                    {transfer.is_total_paid ? (
                      <Chip
                        sx={{ mt: 1, borderRadius: 1 }}
                        size="small"
                        label="PAID"
                        variant="outlined"
                        color="success"
                      />
                    ) : (
                      <Chip
                        sx={{ mt: 1, borderRadius: 1 }}
                        size="small"
                        label="UNPAID"
                        variant="outlined"
                        color="error"
                      />
                    )}
                  </TableCell>
                  {user.role !== 'cashier' && (
                    <TableCell align="center">
                      {transfer.is_comission_paid ? (
                        <Chip
                          sx={{ mt: 1, borderRadius: 1 }}
                          size="small"
                          label="PAID"
                          variant="outlined"
                          color="success"
                        />
                      ) : (
                        <Chip
                          sx={{ mt: 1, borderRadius: 1 }}
                          size="small"
                          label="UNPAID"
                          variant="outlined"
                          color="error"
                        />
                      )}
                    </TableCell>
                  )}
                  <TableCell align="right">{subtotal} Lei</TableCell>
                  {user.role !== 'cashier' && <TableCell align="right">{total} Lei</TableCell>}
                  {user.role !== 'cashier' && (
                    <TableCell align="right">{comission ? comission + ' Lei' : '-'}</TableCell>
                  )}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <NoData />
  );
}
