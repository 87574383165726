import axios from 'axios';
import { store } from './redux/store';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.request.use((config) => {
  const state = store.getState();
  if (state.auth && state.auth.user) {
    config.headers.Authorization = `Bearer ${state.auth.user.token}`;
  }

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location = '/login';
    } else {
      return Promise.reject(error);
    }
  }
);

export default axios;
