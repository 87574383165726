import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from '../redux/slices/usersSlice';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import UsersDrawer from '../components/UsersDrawer';
import NoData from '../components/NoData';

function Users() {
  const dispatch = useDispatch();
  const [openCreate, setOpenCreate] = useState(false);
  const [editUser, setEditUser] = useState(null);

  const usersData = useSelector(({ users: { data } }) => data);
  const usersDataError = useSelector((state) => state.users.error);

  const onDrawerClose = () => {
    setOpenCreate(false);
    setEditUser(null);
  };

  const onCreateOpen = () => {
    setOpenCreate(true);
  };

  const onUpdateOpen = (user) => {
    setEditUser(user);
  };

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    if (usersDataError) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [usersDataError]);

  return (
    <div className="users">
      <Grid sx={{ mb: 4 }} container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3">Users</Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={onCreateOpen}>
            Add user
          </Button>
        </Grid>
      </Grid>
      {usersData.length ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Created</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">First Name</TableCell>
                <TableCell align="left">Last Name</TableCell>
                <TableCell align="left">Role</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersData.map(({ id, created_at, email, first_name, last_name, role, status }, index) => (
                <TableRow
                  key={id}
                  sx={{
                    textAlign: 'right',
                    '&:last-child td, &:last-child th': { borderBottom: 0 },
                    '&:nth-of-type(odd)': (theme) => ({
                      backgroundColor: theme.palette.action.hover,
                    }),
                  }}
                >
                  <TableCell>{dayjs(created_at).format('DD/MM/YYYY HH:mm')}</TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell>{first_name}</TableCell>
                  <TableCell>{last_name}</TableCell>
                  <TableCell>{role}</TableCell>
                  <TableCell>
                    {status === 'ACTIVE' ? (
                      <Chip
                        sx={{ mt: 1, borderRadius: 1 }}
                        size="small"
                        label={`${status}`}
                        variant="outlined"
                        color="success"
                      />
                    ) : (
                      <Chip
                        sx={{ mt: 1, borderRadius: 1 }}
                        size="small"
                        label={`${status}`}
                        variant="outlined"
                        color="error"
                      />
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton size="small" aria-label="update" onClick={() => onUpdateOpen(usersData[index])}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoData />
      )}
      <UsersDrawer open={openCreate || !!editUser} user={editUser} onClose={onDrawerClose} />
    </div>
  );
}

export default Users;
