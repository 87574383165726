import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';

export default function EventsTable({ events }) {
  const user = useSelector((state) => state.auth.user);
  return (
    !!events.length && (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              {user.role !== 'organizer' && <TableCell>Organizer</TableCell>}
              <TableCell>Date/Time</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Sold</TableCell>
              <TableCell>{dayjs().add(-1, 'day').format('DD/MM')}</TableCell>
              <TableCell>{dayjs().format('DD/MM')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map(
              ({
                id,
                date,
                title,
                location,
                city,
                status,
                is_one_plus_one_free,
                is_three_plus_one_free,
                total_sales,
                today_sales,
                yesterday_sales,
                organizer,
              }) => (
                <TableRow
                  component={Link}
                  to={`${id}`}
                  key={id}
                  sx={{
                    textDecoration: 'none',
                    textAlign: 'right',
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:nth-of-type(odd)': (theme) => ({
                      backgroundColor: theme.palette.action.hover,
                    }),
                  }}
                >
                  <TableCell>{id}</TableCell>
                  {user.role !== 'organizer' && <TableCell>{organizer}</TableCell>}
                  <TableCell>{dayjs(date).format('DD/MM/YYYY HH:mm')}</TableCell>
                  <TableCell>
                    {title}
                    {!!is_one_plus_one_free && (
                      <Chip
                        sx={{ ml: 1, borderRadius: 1 }}
                        size="small"
                        label="1+1 Free"
                        variant="outlined"
                        color="success"
                      />
                    )}
                    {!!is_three_plus_one_free && (
                      <Chip
                        sx={{ ml: 1, borderRadius: 1 }}
                        size="small"
                        label="3+1 Free"
                        variant="outlined"
                        color="success"
                      />
                    )}
                  </TableCell>
                  <TableCell>{location}</TableCell>
                  <TableCell>{city}</TableCell>
                  <TableCell>
                    {status === 'SELLING' ? (
                      <Chip
                        sx={{ mt: 1, borderRadius: 1 }}
                        size="small"
                        label={`${status.replace('_', ' ')}`}
                        variant="outlined"
                        color="success"
                      />
                    ) : status === 'NOT_SELLING' ? (
                      <Chip
                        sx={{ mt: 1, borderRadius: 1 }}
                        size="small"
                        label={`${status.replace('_', ' ')}`}
                        variant="outlined"
                        color="error"
                      />
                    ) : (
                      <Chip
                        sx={{ mt: 1, borderRadius: 1 }}
                        size="small"
                        label={`${status.replace('_', ' ')}`}
                        variant="outlined"
                        color="warning"
                      />
                    )}
                  </TableCell>
                  <TableCell>{total_sales}</TableCell>
                  <TableCell>{yesterday_sales}</TableCell>
                  <TableCell>{today_sales}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
}
