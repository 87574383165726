import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

function ColorPicker({ defaultColor, onChange }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [color, setColor] = useState(defaultColor || '#2FBB2F');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'color-picker' : undefined;
  return (
    <div className="color-picker">
      <Box
        sx={{
          backgroundColor: color,
          height: '2rem',
          width: '2rem',
          borderRadius: '5px',
          cursor: 'pointer',
          ':hover': { boxShadow: (theme) => theme.shadows[10] },
        }}
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ChromePicker
          disableAlpha
          color={color}
          onChange={({ hex }) => {
            setColor(hex);
            onChange && onChange(hex);
          }}
        />
      </Popover>
    </div>
  );
}

export default ColorPicker;
