import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCities } from '../redux/slices/citiesSlice';
import { createVenue, updateVenue } from '../redux/slices/venuesSlice';
import validator from 'validator';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FileDropzone from './FileDropzone';

// This is in bytes
const MAX_IMAGE_SIZE = 10000000;
const ACCEPTED_FORMATS = { 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'] };

const empty = (value) => validator.isEmpty(value, { ignore_whitespace: true });
const isLatLng = (value) => validator.isLatLong(value);

function VenuesDrawer({ open, onClose, venue }) {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [coordinate, setCoordinate] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [description, setDescription] = useState('');
  const [selectedPortraitUrl, setSelectedPortraitUrl] = useState('');
  const [selectedCoverUrl, setSelectedCoverUrl] = useState('');
  const [selectedPortrait, setSelectedPortrait] = useState(null);
  const [selectedCover, setSelectedCover] = useState(null);
  const [index, setIndex] = useState(true);
  const [webUrl, setWebUrl] = useState('');

  const cityData = useSelector((state) => state.cities.data);
  const cityDataError = useSelector((state) => state.cities.error);
  const citiesDataLoading = useSelector((state) => state.cities.isLoading);

  const isLoading = useSelector((state) => state.venues.isLoading);

  useEffect(() => {
    dispatch(fetchCities());
  }, [dispatch]);

  useEffect(() => {
    if (cityDataError) {
      toast.error('An error occured, please refresh the page and try again!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [cityDataError]);

  useEffect(() => {
    if (venue) {
      const {
        name,
        city,
        description,
        cover_image_url,
        portrait_image_url,
        address,
        latitude,
        longitude,
        status,
        index_for_search,
        web_url,
      } = venue;
      setSelectedStatus(status);
      setName(name);
      setAddress(address);
      setCoordinate(`${latitude}, ${longitude}`);
      setSelectedCoverUrl(cover_image_url);
      setSelectedPortraitUrl(portrait_image_url);
      const cityObj = cityData.find((c) => c.name === city);
      if (cityObj) {
        setSelectedCity(cityObj.id);
      }
      setIndex(Boolean(index_for_search));
      setDescription(description || '');
      setWebUrl(web_url || '');
    }
  }, [cityData, venue]);

  const onPortrait = useCallback(
    (file) => {
      setSelectedPortrait(file);
    },
    [setSelectedPortrait]
  );

  const onCover = useCallback(
    (file) => {
      setSelectedCover(file);
    },
    [setSelectedCover]
  );

  const onDrawerClose = () => {
    onClose();
    setName('');
    setSelectedCity('');
    setSelectedStatus('');
    setAddress('');
    setCoordinate('');
    setDescription('');
    setSelectedCoverUrl('');
    setSelectedPortraitUrl('');
    setSelectedCover(null);
    setSelectedPortrait(null);
    setWebUrl('');
    setIndex(true);
  };

  const onCreateVenue = () => {
    const { name: cityName } = cityData.find(({ id }) => id === selectedCity);
    const payload = {
      address,
      coordinate,
      name,
      city: cityName,
      index_for_search: index,
      description: description.trim(),
      portrait: selectedPortrait,
      cover: selectedCover,
      web_url: webUrl,
    };
    dispatch(createVenue(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('Venue created successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        onDrawerClose();
      }
    });
  };

  const onUpdateVenue = () => {
    const { name: cityName } = cityData.find(({ id }) => id === selectedCity);
    const payload = {
      venueId: venue.id,
      name,
      city: cityName,
      address,
      status: selectedStatus,
      coordinate,
      index_for_search: index,
      description: description.trim(),
      portrait: selectedPortrait,
      cover: selectedCover,
      web_url: webUrl,
    };
    dispatch(updateVenue(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('Venue updated successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        onDrawerClose();
      }
    });
  };

  const createDisabled = () =>
    isLoading ||
    empty(name) ||
    empty(address) ||
    !isLatLng(coordinate) ||
    !selectedCity ||
    description.length > 512 ||
    !selectedCover ||
    !selectedPortrait ||
    !!(webUrl.trim() && !validator.isURL(webUrl));

  const updateDisabled = () =>
    isLoading ||
    empty(name) ||
    empty(address) ||
    !isLatLng(coordinate) ||
    !selectedCity ||
    description.length > 512 ||
    !!(webUrl.trim() && !validator.isURL(webUrl));

  return (
    <Drawer anchor="right" open={open}>
      <Toolbar />
      <Toolbar>
        <Typography variant="button">{!venue ? 'Create venue' : `Edit venue`}</Typography>
      </Toolbar>
      <Divider />
      <Box sx={{ width: 400, pl: 2, pr: 2 }}>
        <Box sx={{ pb: 2 }} component="form">
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            required
            variant="standard"
            margin="normal"
            size="small"
            fullWidth
            id="name"
            label="Name"
          />
          <TextField
            value={address}
            onChange={(event) => setAddress(event.target.value)}
            required
            variant="standard"
            margin="normal"
            size="small"
            fullWidth
            id="address"
            label="Address"
          />
          <TextField
            value={coordinate}
            onChange={(event) => setCoordinate(event.target.value)}
            required
            variant="standard"
            margin="normal"
            size="small"
            fullWidth
            id="coordinate"
            label="Coordinate"
          />
          <FormControl required variant="standard" margin="normal" size="small" fullWidth disabled={citiesDataLoading}>
            <InputLabel id="city-label">City</InputLabel>
            <Select
              value={selectedCity}
              onChange={(event) => setSelectedCity(event.target.value)}
              labelId="city-label"
              id="city"
            >
              {cityData.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {venue && (
            <FormControl variant="standard" margin="normal" size="small" fullWidth>
              <InputLabel id="venue-status">Status</InputLabel>
              <Select
                value={selectedStatus}
                onChange={(event) => setSelectedStatus(event.target.value)}
                labelId="venue-status"
                id="venue-status"
              >
                <MenuItem value={'ACTIVE'}>Active</MenuItem>
                <MenuItem value={'INACTIVE'}>Inactive</MenuItem>
              </Select>
            </FormControl>
          )}
          <TextField
            multiline
            minRows={5}
            maxRows={10}
            value={description}
            onChange={(event) => setDescription(event.target.value.replace('\n', '\r\n'))}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            name="description"
            label="Description"
            helperText="Max length is 512 characters"
          />
          <TextField
            value={webUrl}
            onChange={(event) => setWebUrl(event.target.value)}
            variant="standard"
            margin="normal"
            size="small"
            fullWidth
            id="url"
            label="Website Url"
          />
          <Box mt={2}>
            <Typography sx={{ display: 'inline-block', marginBottom: 2 }} variant="button">
              Portrait Image *
            </Typography>
            <FileDropzone
              sx={{ height: 300 }}
              previewUrl={selectedPortraitUrl}
              preview
              fileTypes={ACCEPTED_FORMATS}
              maxSize={MAX_IMAGE_SIZE}
              onDrop={onPortrait}
            />
          </Box>
          <Box mt={2} mb={2}>
            <Typography sx={{ display: 'inline-block', marginBottom: 2 }} variant="button">
              Cover Image *
            </Typography>
            <FileDropzone
              sx={{ height: 300 }}
              previewUrl={selectedCoverUrl}
              preview
              fileTypes={ACCEPTED_FORMATS}
              maxSize={MAX_IMAGE_SIZE}
              onDrop={onCover}
            />
          </Box>
          <Typography variant="body2">Max size: 10MB</Typography>
          <Typography sx={{ mb: 2 }} variant="body2">
            File format: JPEG,JPG,PNG
          </Typography>
          <FormGroup sx={{ display: 'block', mt: 2 }}>
            <FormControlLabel
              control={<Switch checked={index} onChange={(event) => setIndex(event.target.checked)} />}
              label="Index in main site"
            />
          </FormGroup>
        </Box>
      </Box>
      <Box sx={{ p: 2, mt: 'auto' }}>
        <Grid columnSpacing={2} container>
          <Grid xs={6} item>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                onDrawerClose();
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid xs={6} item>
            <Button
              disabled={!venue ? createDisabled() : updateDisabled()}
              fullWidth
              variant="contained"
              onClick={!venue ? onCreateVenue : onUpdateVenue}
            >
              {!venue ? 'Create' : 'Update'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}

export default VenuesDrawer;
