import { useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

export default function OrderErrorDialog({ open, errorType }) {
  const errorMessage = useMemo(() => {
    if (errorType === 'EVENT_STARTED_ERROR') return 'Evenimentul a început, cumpărarea biletelor nu mai este posibila!';
    if (errorType === 'EVENT_NOT_AVAILABLE_ERROR')
      return 'Evenimentul nu este disponibil, cumpărarea biletelor nu mai este posibila!';
    if (errorType === 'PRICE_MISMATCH_ERROR') return 'Prețurile au fost actualizate, va rugăm să reîncărcați pagină!';
    return '';
  }, [errorType]);

  return (
    <Dialog open={open}>
      <DialogTitle>Oops!</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>{errorMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {errorType === 'PRICE_MISMATCH_ERROR' ? (
          <Button onClick={() => window.location.reload(true)} variant="contained">
            Reîncarcă
          </Button>
        ) : (
          <Button onClick={() => (window.location.href = 'https://www.poftimcultura.ro')} variant="contained">
            Pagină de start
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
