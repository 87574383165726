import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchEventLight } from '../redux/slices/eventsSlice';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Stack from '@mui/material/Stack';

const fomoThreshold = 20;

function Categories() {
  const theme = useTheme();
  const { eventId } = useParams();

  const dispatch = useDispatch();
  const event = useSelector(({ events: { selectedEvent } }) => selectedEvent);
  const isLoading = useSelector(({ events: { isLoading } }) => isLoading);
  const error = useSelector(({ events: { error } }) => error);

  const redirectToSeatSelector = () => {
    window.top.location.href = `${window.location.origin}/seats/${eventId}`;
  };

  useEffect(() => {
    if (eventId) {
      dispatch(fetchEventLight({ eventId }));
    }
  }, [dispatch, eventId]);

  return (
    <div className="categories">
      {isLoading ? (
        <Typography gutterBottom variant="h5">
          Se încarcă...
        </Typography>
      ) : (
        <>
          <Typography gutterBottom variant="h5">
            Categorii
          </Typography>
          <List>
            {!isLoading &&
              event.tickets.map(({ id, name, color, discount, price, available }) => (
                <ListItem sx={{ borderLeft: `5px solid ${color}` }} key={id} divider>
                  <ListItemText
                    primary={`${name}`}
                    secondary={
                      <>
                        {!!discount && (
                          <Typography variant="body2" style={{ color: theme.palette.error.main }}>{`Economisesti: ${
                            discount * 100
                          }%`}</Typography>
                        )}
                        {available <= fomoThreshold && available > 0 ? (
                          <Typography variant="body2">{`Ultimele ${available} locuri disponibile`}</Typography>
                        ) : !available ? (
                          <Typography variant="body2" style={{ color: theme.palette.error.main }}>
                            Nu mai sunt locuri disponibile
                          </Typography>
                        ) : (
                          ''
                        )}
                        {!!event.is_one_plus_one_free && (
                          <Typography variant="body2">Adaugi 2 bilete în coş şi plăteşti doar 1!</Typography>
                        )}
                        {!!event.is_three_plus_one_free && (
                          <Typography variant="body2">Adaugi 4 bilete în coş şi plăteşti doar 3!</Typography>
                        )}
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    {discount ? (
                      <Stack>
                        <span style={{ textDecoration: 'line-through', fontSize: '0.75rem' }}>
                          {price.toFixed(2) + ' Lei'}
                        </span>
                        <span style={{ color: theme.palette.error.main }}>
                          {(price - price * discount).toFixed(2) + ' Lei'}
                        </span>
                      </Stack>
                    ) : (
                      price.toFixed(2) + ' Lei'
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              fullWidth
              onClick={redirectToSeatSelector}
              disabled={
                error ||
                isLoading ||
                event.status === 'NOT_SELLING' ||
                event.status === 'ARCHIVED' ||
                event.status === 'DELETED'
              }
              variant="contained"
            >
              Selectează loc
            </Button>
          </Box>
        </>
      )}
    </div>
  );
}

export default Categories;
