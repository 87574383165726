import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { removeToken } from '../redux/slices/authSlice';

function RequireAuth({ children }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  if (!user || Date.now() >= user.exp * 1000) {
    dispatch(removeToken());
    return <Navigate to="/login" />;
  }

  return children;
}

export default RequireAuth;
