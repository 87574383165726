import React, { useEffect, useState } from 'react';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createPaymentPoint, updatePaymentPoint } from '../redux/slices/paymentPointsSlice';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const empty = (value) => !validator.isEmpty(value, { ignore_whitespace: true });

function PaymentPointsDrawer({ open, onClose, paymentPoint, cities }) {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [reprName, setReprName] = useState('');

  const isLoading = useSelector((state) => state.paymentPoints.isLoading);

  const onDrawerClose = () => {
    onClose();
    if (!paymentPoint) {
      setName('');
      setReprName('');
      setSelectedStatus('');
      setSelectedCity('');
    }
  };

  useEffect(() => {
    if (paymentPoint) {
      const { name, representative_full_name, city, status } = paymentPoint;
      setName(name);
      setReprName(representative_full_name);
      setSelectedStatus(status);

      const cityObj = cities.find((c) => c.name === city);
      if (cityObj) {
        setSelectedCity(cityObj.id);
      }
    }
  }, [paymentPoint, cities]);

  const onCreate = () => {
    const { name: cityName } = cities.find(({ id }) => id === selectedCity);
    const payload = {
      name: name.trim(),
      representative_full_name: reprName.trim(),
      city: cityName,
    };
    dispatch(createPaymentPoint(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('Payment point created successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        onDrawerClose();
      }
    });
  };

  const onUpdate = () => {
    const { name: cityName } = cities.find(({ id }) => id === selectedCity);
    const payload = {
      paymentPointId: paymentPoint.id,
      name: name.trim(),
      representative_full_name: reprName.trim(),
      city: cityName,
      status: selectedStatus,
    };
    dispatch(updatePaymentPoint(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('Payment point updated successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        onDrawerClose();
      }
    });
  };

  return (
    <Drawer anchor="right" open={open}>
      <Toolbar />
      <Toolbar>
        <Typography variant="button">{!paymentPoint ? 'Create payment point' : 'Edit payment point'}</Typography>
      </Toolbar>
      <Divider />
      <Box sx={{ width: 400, pl: 2, pr: 2 }}>
        <Box component="form">
          <TextField
            required
            value={name}
            onChange={(event) => setName(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            name="name"
            label="Name"
          />
          <TextField
            required
            value={reprName}
            onChange={(event) => setReprName(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            label="Representative full name"
            type="text"
          />
          <TextField
            select
            required
            value={selectedCity}
            onChange={(event) => setSelectedCity(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            name="city"
            label="City"
          >
            {cities.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>
          {paymentPoint && (
            <TextField
              select
              value={selectedStatus}
              onChange={(event) => setSelectedStatus(event.target.value)}
              fullWidth
              variant="standard"
              margin="normal"
              size="small"
              name="status"
              label="Status"
            >
              <MenuItem value={'ACTIVE'}>ACTIVE</MenuItem>
              <MenuItem value={'INACTIVE'}>INACTIVE</MenuItem>
            </TextField>
          )}
        </Box>
      </Box>
      <Box sx={{ p: 2, mt: 'auto' }}>
        <Grid columnSpacing={2} container>
          <Grid xs={6} item>
            <Button fullWidth variant="outlined" onClick={onDrawerClose}>
              Cancel
            </Button>
          </Grid>
          <Grid xs={6} item>
            <Button
              sx={{ label: { color: 'red' } }}
              disabled={isLoading || !empty(name) || !empty(reprName) || !selectedCity}
              fullWidth
              variant="contained"
              onClick={!paymentPoint ? onCreate : onUpdate}
            >
              {!paymentPoint ? 'Create' : 'Update'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}

export default PaymentPointsDrawer;
